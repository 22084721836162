import React, { useState } from 'react';
import { Button, ModalBody, ModalFooter, Input } from 'reactstrap';
import { Product, useUpdateProductMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

// TODO: Do we want to show a loading indicator and close after success?
const UpdateQuantityModal = ({
  product,
  setIsOpen,
  refetch
}: {
  product: Product,
  setIsOpen: Function,
  refetch: Function
}) => {
  const [quantity, setQuantity] = useState<number | undefined>(product.configuration_quantity);
  const [loading, setLoading] = useState(false);
  const [updateProduct] = useUpdateProductMutation();
  const isValid = quantity !== undefined && (quantity > 0 && quantity < 1000);

  const onInput = (name: string, value: string) => {
    const quantity: number = parseInt(value);
    if (quantity >= 0 && quantity <= 999)
      setQuantity(quantity)
    else
      setQuantity(undefined)
  };

  const save = () => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    updateProduct({
      variables: {
        pk_columns: { id: product.id },
        _set: { configuration_quantity: quantity },
      },
      onCompleted: () => {
        refetch();
        setLoading(false);
        setIsOpen(false);
      },
      onError: () => {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <form onSubmit={(e) => {
        e.preventDefault();
        save();
      }}>
        <div className="modal-header">
          <h3>Update quantity</h3>
          <FontAwesomeIcon
            style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
            onClick={() => { if (!loading) setIsOpen(false); }}
            icon={faXmarkCircle} />
        </div>
        <ModalBody>
          <div className="d-flex flex-column mb-3">
            <label>Quantity</label>
            <Input
              autoFocus
              name="quantity"
              type='number'
              min={1}
              max={999}
              value={quantity}
              onInput={(e) => onInput((e.target as any).name, (e.target as any).value)}
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start mx-2">
          <Button type='submit' disabled={loading || !isValid} onClick={save}>Update Quantity</Button>
        </ModalFooter>
      </form>
    </div>
  );
};

export default UpdateQuantityModal;