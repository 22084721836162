import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Input } from 'reactstrap';

import { GetJobsDocument, GetJobsQuery, useInsertJobMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

// TODO: Do we want to show a loading indicator and close after success?
const AddJobModal = ({
  onSuccess
}: { onSuccess: Function }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [job, setJob] = useState({
    name: ''
  });
  const isValid = !!job.name;

  const [createJob, { loading }] = useInsertJobMutation();

  const onInput = (name: string, value: string) => {
    setJob({
      ...job,
      [name]: value
    });
  };

  const onCreate = () => {
    createJob({
      variables: { object: { name: job.name } },
      update: (cache, { data }) => {
        const existing = cache.readQuery<GetJobsQuery>({
          query: GetJobsDocument,
        });
        const inserted = data?.insert_job_one;
        if (!inserted) return;
        cache.writeQuery({
          query: GetJobsDocument,
          data: {
            job: existing?.job.concat([{
              ...inserted,
              quotes_aggregate: {},
              products_aggregate: {}
            }]),
          },
        });
      },
      onCompleted: () => {
        setIsOpen(false);
        setJob({
          name: ''
        });
        onSuccess();
      }
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !loading && isValid) onCreate();
  };

  return (<>
    <Button style={{ width: 100, height: 40, margin: 0, padding: 0 }} onClick={() => setIsOpen(true)}>Add Job</Button>
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} autoFocus={false}>
      <div className="modal-header">
        <h3>Add new job</h3>
        <FontAwesomeIcon
          style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
          onClick={() => { if (!loading) setIsOpen(false); }}
          icon={faXmarkCircle} />
      </div>
      <ModalBody>
        <div className="d-flex flex-column mb-3">
          <label>Job Name</label>
          <Input
            autoFocus
            name="name"
            value={job.name}
            onInput={(e) => onInput((e.target as any).name, (e.target as any).value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-start mx-2">
        <Button disabled={loading || !isValid} onClick={onCreate}>Add Job</Button>
      </ModalFooter>
    </Modal>
  </>);
};

export default AddJobModal;