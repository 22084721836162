import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Serial_Number, useUpdateProductMutation } from '../generated/graphql';
import controlsTeamExportHelpers from '../helpers/controls-team-export';

const AddControlsInfoModal = ({
  product,
  serialNumbers,
  isOpen = true,
  toggle,
  onSuccess,
  readOnly
}) => {


const [productControlsDetails, setProductControlsDetails] = useState({
    air_flow_orientation: null,
    fan_layout: null,
    set_voltage: null,
    BACnet: null,
    local_HMI: null,
});

const [isLoading, setIsLoading] = useState(false);

const validAirFlowOrientation = productControlsDetails.air_flow_orientation === "H"
        || productControlsDetails.air_flow_orientation === "V";
const validFanLayout = !!productControlsDetails.fan_layout !== false;
const validSetVoltage = (product?.power === 460 && productControlsDetails?.set_voltage === "460")
        || (product?.power !== 460 && (productControlsDetails?.set_voltage === "230" || productControlsDetails?.set_voltage === "208"));
const validBACnet = (product?.mas_ec_plus && (productControlsDetails.BACnet === "IP" || productControlsDetails.BACnet === "MSTP"))
        || (!product?.mas_ec_plus && productControlsDetails.BACnet === null);
const validLocalHMI = (product?.mas_ec_plus && (productControlsDetails.local_HMI === "10_inch" || productControlsDetails.local_HMI === "none"))
        || (!product?.mas_ec_plus && productControlsDetails.local_HMI === null);

const isValid = (
    validAirFlowOrientation &&
    validFanLayout &&
    validSetVoltage &&
    validBACnet &&
    validLocalHMI
);

const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value: any = "";
    value = e.target.value;

    if (e.target.name === "local_HMI" && e.target.value === "none"){
        if (window.confirm("Are you sure you want to set HMI to none? This option should only be used in special circumstances.")){
            e.target.value = "none";
        } else {
            return;
        }
    } else if (e.target.name === "BACnet" && e.target.value === "IP") {
        if (window.confirm("Are you sure you want to set BACnet to IP? This option should only be used in special circumstances.")){
            e.target.value = "none";
        } else {
            return;
        }
    }
    setProductControlsDetails({ ...productControlsDetails, [e.target.name]: value });
};

const [updateProduct] = useUpdateProductMutation();
const onSubmitForm = () => {
    // Quit if not ready
    if (!isValid || isLoading) return;
    // Set loading
    setIsLoading(true);
    // Convert hmi from input to db value, create model number
    if (productControlsDetails.local_HMI === "10_inch") productControlsDetails.local_HMI = true;
    else if (productControlsDetails.local_HMI === "none") productControlsDetails.local_HMI = false;
    const modelNumber = controlsTeamExportHelpers.calculateModelNumber({...product, ...productControlsDetails});
    // Update product and turn off loading
    updateProduct({
        variables: {
            pk_columns: { id: product.id },
            _set: {...productControlsDetails, model_number : modelNumber},
        },
        onCompleted: () => {
            onSuccess();
            setIsLoading(false);
            toggle();
        },
    });
};

useEffect(() => {
    if (!isOpen){
        setProductControlsDetails({
            air_flow_orientation: null,
            fan_layout: null,
            set_voltage: null,
            BACnet: null,
            local_HMI: null,
        });
    } else {
        if (product?.model_number !== null) {
            let my_hmi = null;
            if (product?.mas_ec_plus){
                my_hmi = product?.local_HMI ? "10_inch" : "none";
            } else {
                my_hmi = null;
            }
            setProductControlsDetails({
                air_flow_orientation: product?.air_flow_orientation,
                fan_layout: product?.fan_layout,
                set_voltage: !!product?.set_voltage ? product.set_voltage.toString() : null,
                BACnet: product?.BACnet,
                local_HMI: my_hmi,
            });
        } else {
            let default_hmi = null;
            let default_BACnet = null;
            if (product?.mas_ec_plus){
                default_hmi = "10_inch";
                default_BACnet = "MSTP";
            } 
            setProductControlsDetails({
                air_flow_orientation: null,
                fan_layout: null,
                set_voltage: product?.power === 460 ? "460" : null,
                BACnet: default_BACnet,
                local_HMI: default_hmi,
            });
        }
    }
  }, [isOpen, product?.BACnet, product?.air_flow_orientation, product?.fan_layout, product?.local_HMI, product?.mas_ec_plus, product?.model_number, product?.power, product?.set_voltage]);

const mySerials = 
    serialNumbers?.serial_number.filter((e : Serial_Number) => e.product_id === product?.id).length > 0 
    ? serialNumbers?.serial_number.filter((e : Serial_Number) => e.product_id === product?.id) 
    : null;

return (
    <>
        <Modal isOpen={isOpen} toggle={() => toggle()} backdrop={"static"}>
            <Form onSubmit={(e) => { e.preventDefault(); onSubmitForm() }}>
                <div className="modal-header">
                    <h3>Additional Info</h3>
                    <FontAwesomeIcon
                    style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => { if (!isLoading) toggle(); }}
                    icon={faXmarkCircle} />
                </div>
                <ModalBody>
                    <table className="properties-holder">
                        <tr>
                            <td>Tag</td>
                            <td>{product?.tag}</td>
                        </tr>
                        <tr>
                            <td>Model Number</td>
                            <td>{product?.model_number === null ? "(Complete Information to Generate)" : product?.model_number }</td>
                        </tr>
                        <tr>
                            <td>{mySerials?.length === 1 ? "Serial Number" : "Serial Numbers"}</td>
                            <td>{mySerials?.map((e: { serial_number: any; }) => e.serial_number).join(", ")}</td>
                        </tr>
                        <tr>
                            <td>k Factor</td>
                            <td>{product?.configuration?.k_factor}</td>
                        </tr>
                        <tr>
                            <td>AFMS dP Max</td>
                            <td>
                                {(product?.air_flow / product?.configured_fan_count) / product?.configuration?.k_factor > 4.5 
                                ? "0 - 10" : "0 - 5"}
                            </td>
                        </tr>
                    </table>
                    <hr/>
                    <div className="input-holder">
                        <div className="box-label">Air Flow Orientation</div>
                        <FormGroup>
                            <Input
                                name="air_flow_orientation"
                                type="select"
                                value={productControlsDetails?.air_flow_orientation || ""}
                                onChange={handleChange}
                                invalid={!validAirFlowOrientation}
                                disabled={readOnly}
                            >
                                <option value={null}></option>
                                <option value={"H"}>Horizontal</option>
                                <option value={"V"}>Vertical</option>
                            </Input>
                        </FormGroup>
                        <div className="box-label">Fan Layout</div>
                        <FormGroup>
                            <Input
                                name="fan_layout"
                                type="select"
                                value={productControlsDetails?.fan_layout || ""}
                                onChange={handleChange}
                                invalid={!validFanLayout}
                                disabled={readOnly}
                            >
                                <option value={null}></option>
                                <option value={"A"}>A</option>
                                <option value={"B"}>B</option>
                                <option value={"C"}>C</option>
                                <option value={"D"}>D</option>
                                <option value={"E"}>E</option>
                                <option value={"X"}>X</option>
                            </Input>
                        </FormGroup>
                        <div className={product?.power === 460 ? "hidden-enabled box-label" : "box-label"}>Voltage</div>
                        <FormGroup className={product?.power === 460 ? "hidden-enabled" : ""}>
                            <Input
                                name="set_voltage"
                                type="select"
                                value={productControlsDetails?.set_voltage || ""}
                                onChange={handleChange}
                                invalid={!validSetVoltage}
                                disabled={readOnly}
                            >
                                {
                                    product?.power === 460 &&
                                    <option value={"460"}>460</option>
                                }
                                {
                                    product?.power !== 460 &&
                                    <>
                                        <option value={null}></option>
                                        <option value={"208"}>208</option>
                                        <option value={"230"}>230</option>
                                    </>
                                }
                            </Input>
                        </FormGroup>
                        <div className={!product?.mas_ec_plus ? "hidden-enabled box-label" : "box-label"}>BACnet</div>
                        <FormGroup className={!product?.mas_ec_plus ? "hidden-enabled" : ""}>
                            <Input
                                name="BACnet"
                                type="select"
                                value={productControlsDetails?.BACnet || ""}
                                onChange={handleChange}
                                invalid={!validBACnet}
                                disabled={readOnly}
                            >
                                {
                                    !product?.mas_ec_plus &&
                                    <option value={null}></option>
                                }
                                {
                                    product?.mas_ec_plus &&
                                    <>
                                        <option value={"MSTP"}>MSTP</option>
                                        <option value={"IP"}>IP</option>
                                    </>
                                }
                            </Input>
                        </FormGroup>
                        <div className={!product?.mas_ec_plus ? "hidden-enabled box-label" : "box-label"}>Local HMI</div>
                        <FormGroup className={!product?.mas_ec_plus ? "hidden-enabled" : ""}>
                            <Input
                                name="local_HMI"
                                type="select"
                                value={productControlsDetails?.local_HMI || ""}
                                onChange={handleChange}
                                invalid={!validLocalHMI}
                                disabled={readOnly}
                            >
                                {
                                    !product?.mas_ec_plus &&
                                    <option value={null}></option>
                                }
                                {
                                    product?.mas_ec_plus &&
                                    <>
                                        <option value={"10_inch"}>10"</option>
                                        <option value={"none"}>None</option>
                                    </>
                                }
                            </Input>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-start mx-2">
                    <Button disabled={isLoading || !isValid || readOnly} type='submit'>
                        Submit
                    </Button>
                </ModalFooter>
            </Form>
    </Modal>
</>);
}

export default AddControlsInfoModal;