import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { Col, Row, Container } from "reactstrap";

import "./Profile.css";

const Profile = () => {
  const { keycloak } = useKeycloak();

  return (
    <Container className="profile-container mh-100 d-flex">
      <Row>
        <Col>
          {Object.entries(keycloak.userInfo ?? {}).map(([k, v]) => (
            <Row>{`${k}: ${v}`}</Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
