import "./Tools.css"
import { useState, ChangeEvent } from "react";
import { Configuration, useGetCostsQuery, useGetFanFitsQuery, useGetControlPanelSizingQuery, GetConfigurationsQuery, Control_Panel_Sizing, useGetConfigurationsQuery } from "../generated/graphql";
import { DesignParameters, findMaxCFM } from "../helpers/fan-curve";
import { RPMCoefficient } from "../helpers/file-checker";
import { calculateMaximumNumberOfFans, iterateFanCount, calculateRPMSoundPower, calculateOperatingInputHorserpower, calculateMCA, calculateMOCP, calculateSoundPowerReturnSum, calculateSoundPowerDischargeSum, calculateEfficiency, calculateRedundancy, getCostBreakdown, calculateFanArrayWeight } from "../helpers/generate-configuration-query";
import parseMoney from "../helpers/money-utils";
import { validateAirflow, validatePressure, validateWidth, validateHeight } from "../helpers/product";
import { Input, FormFeedback, Spinner } from "reactstrap";
import { ToolsConfigDisplay } from "../components/ToolsConfigDisplay";

interface Config {
    configuration: Configuration,
    designIteration: DesignParameters,
    fla: number,
    mca: number,
    mocp: number,
    inputPower: number,
    efficiency: number,
    redundancy: number,
    inletSound: number,
    outletSound: number,
    sizing?: Control_Panel_Sizing | undefined,
    unitCost?: number,
    arrayWeight?: number,
}

export default function Calculator() {

    const [productDetails, setProductDetails] = useState<any>({
        air_flow: 3000,
        total_static_pressure: 3,
        fan_count: 2,
        fan_model: "N88-56605",
        width: 100,
        height: 100,
        power: 460,
        backdraft_dampers: false,
        pressure_transducers: false,
        mas_ec_plus: false
    });

    const [touchedAirflow, setTouchedAirflow] = useState(false);
    const [touchedPressure, setTouchedPressure] = useState(false);
    const [touchedWidth, setTouchedWidth] = useState(false);
    const [touchedHeight, setTouchedHeight] = useState(false);
    const [configuration, setConfiguration] = useState<Config>();
    const [hasSearched, setHasSearched] = useState(false);

    const validAirflow =
        !touchedAirflow || validateAirflow(productDetails.air_flow);
    const validPressure =
        !touchedPressure || validatePressure(productDetails.total_static_pressure);
    const validWidth = !touchedWidth || validateWidth(productDetails.width);
    const validHeight = !touchedHeight || validateHeight(productDetails.height);
    // const isValid =
    //     validTag && validAirflow && validPressure && validWidth && validHeight;

    const { data: costs } = useGetCostsQuery();
    const { data: fanFitData } = useGetFanFitsQuery();
    const { data: panelSizing } = useGetControlPanelSizingQuery();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";

        if (e.target.name === "outdoor_rating"){
            if (e.target.value === "indoor") value = false;
            if (e.target.value === "outdoor") value = true;
        } else if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number") {
            try {
                value = parseFloat(e.target.value);
            } catch (error) {
                console.error("Parse error: ", error);
                return;
            }
        } else {
            value = e.target.value;
        }

        if (e.target.name === "mas_ec_plus" && e.target.checked === false){
            setProductDetails({ ...productDetails, [e.target.name]: value, "outdoor_rating": null });
          } else {
            setProductDetails({ ...productDetails, [e.target.name]: value });
        }
    }

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        refetch({ voltage: productDetails.power }).then(({ data }) => {
            setHasSearched(true);
            onQueryCompleted(data)
        }).catch((error) => {
            console.error(error);
        });
    }

    const onQueryCompleted = (data: GetConfigurationsQuery) => {
        let possibleConfigurations: Config[] = [];

        for (let configuration of data?.configuration || []) {
            const fanFit = fanFitData?.fan_fit.find((f) => f.fan_diameter === configuration.blade_diameter && f.manufacturer === configuration.manufacturer);
            const fanCount = calculateMaximumNumberOfFans(
                {
                    width: productDetails.width || 0,
                    height: productDetails.height || 0,
                    backdraft_dampers: productDetails.backdraft_dampers || false,
                },
                configuration,
                fanFit
            );

            const rawCoefficients = !!configuration?.rmp_coefficients
                ? JSON.parse(configuration?.rmp_coefficients)
                : null;

            let coefficients: RPMCoefficient[] = rawCoefficients
                ? Object.values(rawCoefficients)
                : [];

            coefficients = productDetails.backdraft_dampers ?
                coefficients.map(c => Object.assign({}, c, {
                    pressure: {
                        a: c.pressure.a,
                        b: c.pressure.b,
                        c: c.pressure.c,
                        d: c.pressure.d - 0.2
                    }
                })) :
                coefficients;

            const fla = configuration.fla_460 ?? configuration.fla_400;

            const designIterations = iterateFanCount(
                productDetails?.air_flow ?? 0,
                productDetails.total_static_pressure ?? 0,
                fanCount,
                coefficients,
                configuration.model
            );

            // const maxRPM = Math.max(...(Object.keys(coefficients).map((k) => parseInt(k))));
            const maxRPMCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM)[0]; //.find((c: RPMCoefficient) => c.RPM === maxRPM)
            const designConfigurations = designIterations.map((di) => {
                const maxCFM = maxRPMCoefficients
                    ? findMaxCFM({
                        tsp: productDetails.total_static_pressure ?? 0,
                        coefficient: maxRPMCoefficients.pressure,
                    })
                    : 0;

                const iterationSp = di.lower.coefficient && di.upper.coefficient && calculateRPMSoundPower(di.fanCount, productDetails.air_flow / di.fanCount, di.lower.coefficient, di.upper.coefficient, di.interpolation);
                const horsepower = di.upper.coefficient?.powerHp && di.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
                    productDetails.air_flow,
                    di.fanCount || 0,
                    di.lower.coefficient?.powerHp,
                    di.upper.coefficient?.powerHp,
                    di.interpolation
                ) : 0;

                return ({
                    configuration,
                    designIteration: di,
                    fla,
                    mca: calculateMCA({ fla, fanCount: di.fanCount }),
                    mocp: calculateMOCP({ fla, fanCount: di.fanCount }),
                    inletSound: calculateSoundPowerReturnSum(iterationSp),
                    outletSound: calculateSoundPowerDischargeSum(iterationSp),
                    inputPower: horsepower,
                    efficiency: calculateEfficiency({
                        fanCount: di.fanCount ?? 0,
                        tsp: productDetails.total_static_pressure ?? 0,
                        cfm: productDetails?.air_flow ?? 0,
                        horsepower,
                    }),
                    redundancy: calculateRedundancy({
                        maxCFM,
                        fanCount: di.fanCount,
                        designCFM: productDetails?.air_flow ?? 0,
                    })
                });
            });

            possibleConfigurations = possibleConfigurations.concat(designConfigurations as any);
        }

        possibleConfigurations = possibleConfigurations.filter(c => (c.designIteration.fanCount === productDetails.fan_count) && (c.configuration.model === productDetails.fan_model)).map((pc) => {
            const sizing = panelSizing?.control_panel_sizing.find(
                (p) => p.fan_count === pc.designIteration.fanCount 
                && p.amperage === calculateMOCP({fla: pc.fla, fanCount: p.fan_count})
            );

            // if we didn't find a panel with equivalent sizing, just get max panel so we can calculate
            let maxSizing : Control_Panel_Sizing = null;
            let maxFans : number = 0
            let customPanelCost : number = 0;
            panelSizing?.control_panel_sizing.forEach(
                (panel_size) => {
                    if (panel_size.fan_count > maxFans){
                        maxSizing = panel_size;
                        maxFans = panel_size.fan_count;
                    }
                }
            )
            if (sizing === undefined) customPanelCost = maxSizing?.cost + (100 * (pc.designIteration.fanCount - sizing?.fan_count));

            const costBreakdown = getCostBreakdown({
                fanCost: parseMoney(pc.configuration?.price) ?? 0,
                fanCount: pc.designIteration.fanCount ?? 1,
                controlPanelCost: sizing?.cost ?? customPanelCost,
                bulkheadHeight: productDetails.height ?? 0,
                bulkheadWidth: productDetails.width ?? 0,
                costPerSQFT:
                    parseMoney(costs?.cost?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value) ?? 0,
                miscFlatCost:
                    parseMoney(costs?.cost?.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
                miscVariableCost:
                    parseMoney(costs?.cost?.find((c) => c.name === "Misc_Variable:")?.value) ?? 0,
                hasTransducers: productDetails.pressure_transducers ?? false,
                hasDampers: productDetails.backdraft_dampers ?? false,
                damperCost:
                    parseMoney(costs?.cost?.find((c) => c.name === "Backdraft_Damper")?.value) ?? 250,
            })

            // console.log(sizing);

            return ({
                ...pc,
                sizing: sizing ?? maxSizing,
                arrayWeight: calculateFanArrayWeight({
                    fanCount: pc.designIteration.fanCount,
                    fanWeight: pc.configuration.fan_weight,
                    panelWeight: sizing?.weight || 0,
                    bulkheadHeight: productDetails.height || 0,
                    bulkheadWidth: productDetails.width || 0,
                }),
                unitCost: costBreakdown.totalCost
            });
        });

        if (possibleConfigurations.length > 0)
            setConfiguration(possibleConfigurations[0]);
        else
            setConfiguration(undefined);
    }

    const { loading, refetch } = useGetConfigurationsQuery({
        skip: true,
        variables: { voltage: productDetails.power },
        onCompleted: onQueryCompleted
    });

    return (<div className="tools-content">
        <form onSubmit={onSubmit}>
            <div>
                <div className="box-label">Fan Model</div>
                <Input
                    name="fan_model"
                    type="text"
                    value={productDetails.fan_model}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Fan Count</div>
                <Input
                    name="fan_count"
                    type="number"
                    value={productDetails.fan_count}
                    onChange={handleChange}
                    invalid={productDetails.fan_count < 1}
                />
            </div>
            <div>
                <div className="box-label">Air Flow (CFM)</div>
                <Input
                    name="air_flow"
                    type="number"
                    placeholder="air flow"
                    value={productDetails.air_flow}
                    onChange={handleChange}
                    invalid={!validAirflow}
                    onBlur={() => setTouchedAirflow(true)}
                />
            </div>
            <div>
                <div className="box-label">Total SP</div>
                <Input
                    name="total_static_pressure"
                    type="number"
                    value={productDetails.total_static_pressure}
                    onChange={handleChange}
                    invalid={!validPressure}
                    onBlur={() => setTouchedPressure(true)}
                />
                <FormFeedback>2 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Width</div>
                <Input
                    name="width"
                    type="number"
                    value={productDetails.width}
                    onChange={handleChange}
                    invalid={!validWidth}
                    onBlur={() => setTouchedWidth(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Height</div>
                <Input
                    name="height"
                    type="number"
                    value={productDetails.height}
                    onChange={handleChange}
                    invalid={!validHeight}
                    onBlur={() => setTouchedHeight(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Power</div>
                <Input
                    name="power"
                    type="select"
                    value={productDetails.power}
                    onChange={handleChange}
                >
                    <option value={460}>460V/3~/60HZ</option>
                    <option value={208}>208V/3~/60HZ</option>
                </Input>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="backdraft_dampers"
                        type="checkbox"
                        checked={productDetails.backdraft_dampers || false}
                        onChange={handleChange}
                    /> Backdraft Dampers</label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="pressure_transducers"
                        type="checkbox"
                        checked={productDetails.pressure_transducers || false}
                        onChange={handleChange}
                    /> Pressure Transducers</label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="mas_ec_plus"
                        type="checkbox"
                        checked={productDetails.mas_ec_plus || false}
                        onChange={handleChange}
                    /> MAS EC+</label>
            </div>
            <div className={!!productDetails.mas_ec_plus ? "collapse-open" : "collapse-closed"}>
                <label className="radio-holder">
                    <input 
                    type="radio" 
                    name="outdoor_rating" 
                    className="radio-button"
                    value="indoor"
                    checked={!productDetails.outdoor_rating} 
                    onChange={handleChange}
                    />
                    Indoor
                </label>
                <label>
                    <input 
                    type="radio" 
                    name="outdoor_rating" 
                    className="radio-button"
                    value="outdoor"
                    checked={!!productDetails.outdoor_rating} 
                    onChange={handleChange}
                    />
                    Outdoor
                </label>
            </div>
            <button type="submit">Submit</button>
        </form>
        <div className="results">
            {loading && <div className="loading">
                <Spinner />
            </div>}
            {!loading && !hasSearched && <div />}
            {!loading && !configuration && hasSearched && <div className="no-results">
                No results found
            </div>}
            {!loading && !!configuration && <div className="calculator-result">
                <ToolsConfigDisplay
                    configuration={configuration.configuration}
                    fanCount={configuration.designIteration.fanCount}
                    product={productDetails}
                    costs={costs?.cost}
                    key={configuration.configuration.id}
                    sizing={configuration.sizing}
                />
            </div>}
        </div>
    </div>);
};