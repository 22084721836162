import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
// import { useMutation } from '@apollo/client';
import * as EmailValidator from 'email-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

// import { CREATE_USER } from '../graphql/user';

// import { User } from '../types';

// TODO: Do we want to show a loading indicator and close after success?
const AddUserModal = ({
  onSuccess
}: { onSuccess: Function }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'user',
  });
  const isValid = !!user.firstName
    && !!user.lastName
    && !!user.role
    && !!user.email
    && EmailValidator.validate(user.email);

//   const [createUser, { loading, error, data }] = useMutation(CREATE_USER);
  
  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => setUser({
    ...user,
    [e.target.name]: e.target.value
  });

  const onCreate = () => {
    setIsOpen(false);
    setUser({
      firstName: '',
      lastName: '',
      email: '',
      role: 'user',
    });
    onSuccess();
  };

  return (<>
    <Button onClick={() => setIsOpen(true)}>Add User</Button>
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <div className="modal-header">
        <h3>Add new user</h3>
        <FontAwesomeIcon
          style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
          onClick={() => { setIsOpen(false); }}
          icon={faXmarkCircle} />
      </div>
      <ModalBody>
        <h3>Add a new user</h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-column">
            <label>First Name</label>
            <Input name="firstName" value={user.firstName} onInput={onInput} />
          </div>
          <div className="d-flex flex-column">
            <label>Last Name</label>
            <Input name="lastName" value={user.lastName} onInput={onInput} />
          </div>
        </div>
        <div className="d-flex flex-column mb-3">
          <label>Email</label>
          <Input name="email" value={user.email} onInput={onInput} />
        </div>
        <div className="d-flex flex-column mb-3">
          <label>Role</label>
          <Input type='select' name="role" value={user.role} onChange={onInput}>
            <option value=''>- Select -</option>
            <option value='admin'>Admin</option>
            <option value='user'>User</option>
          </Input>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-start">
        {/* TODO: Add is loading check */}
        <Button disabled={!isValid} onClick={onCreate}>Add User</Button>
      </ModalFooter>
    </Modal>
  </>);
};

export default AddUserModal;