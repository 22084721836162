import React from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";

import { LOGGED_IN_REDIRECT_URL } from '../constants';

const GuideRoute = ({ children }: { children: any }) => {
  const { keycloak } = useKeycloak();

  return !keycloak.authenticated ? children : <Navigate to={LOGGED_IN_REDIRECT_URL} />;
};

export default GuideRoute;
