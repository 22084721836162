import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, Button, Col, Input } from 'reactstrap';
import { BlockBlobClient } from '@azure/storage-blob';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import {
  Configuration,
  useGetFanModelFileLazyQuery,
  useUpsertFanModelFileMutation,
  useSearchConfigurationByModelLazyQuery
} from '../generated/graphql';

const root = process.env.REACT_APP_AZURE_FUNCTIONS_URL || "";

const UploadModelFiles = () => {
  const [model, setModel] = useState('');
  const [type, setType] = useState<'front' | 'side' | 'top' | 'control-panel'>('front');
  const [file, setFile] = useState<any>('');
  const [fileHasError, setFileHasError] = useState(false);
  const [isSafe, setSafe] = useState(undefined as unknown as boolean);

  const [loading, setLoading] = useState(false);

  const [getFanModelFile] = useGetFanModelFileLazyQuery();
  const [upsertFanModelFile] = useUpsertFanModelFileMutation();

  const [searchConfigurationByModel] = useSearchConfigurationByModelLazyQuery();

  const [searchResultConfigurations, setSearchResultConfigurations] = useState<any[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState(undefined as unknown as Configuration);
  const [isConfigurationRequestCancelled, setConfigurationRequestCancelled] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const captureModel = (e: any) => setModel(e.target.value);
  const captureType = (e: any) => setType(e.target.value);

  const modelFieldRef = React.useRef<HTMLInputElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    return () => {
      // Cancels ongoing fan model request on component unmount
      setConfigurationRequestCancelled(true);
    };
  }, []);

  const searchConfiguration = React.useCallback(async () => {
    setFile(null);
    setConfigurationRequestCancelled(false);
    setSafe(undefined as unknown as boolean);
    setSelectedConfiguration(undefined as unknown as Configuration);

    setLoadingData(true);

    let results: React.SetStateAction<any[]> = [];

    try {
      //N88-50322
      let trimmed_model = `${model}`.trim();

      if (!trimmed_model) {
        modelFieldRef.current?.focus();
      }
      else {
        const { data } = await searchConfigurationByModel({
          variables: {
            search: `%${trimmed_model}%`,
          },
          context: {
            fetchOptions: {
              signal: isConfigurationRequestCancelled ? undefined : new AbortController().signal,
            },
          },
        });
        
        results = data?.configuration || [];
      }
    }
    catch( e ) {
      console.error( e );
    }
    finally {
      setLoadingData(false);
      setSearchResultConfigurations(results);
    }
  }
  , [model, isConfigurationRequestCancelled, searchConfigurationByModel]);

  const captureFile = (e: any) => {
    setFile(null);
    setFileHasError( false );
    setSafe(undefined as unknown as boolean);

    let selected_file = e.target.files[0];
    let file_name = selected_file.name;

    // Validate that name has .dxf extension
    if ( !file_name.endsWith( '.dxf' ) ) {
      setFileHasError( true );
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = fr => setFile(fr?.target?.result);
  };

  const upload = React.useCallback(async () => {
    if ( typeof selectedConfiguration?.model === 'undefined' || !file ) {
      return;
    }

    if ( typeof isSafe == typeof undefined ) {
      toast.error( 'Please confirm that this fan model is safe or unsafe to use.' );
      return;
    }

    setLoading(true);

    // Assign local model variable andd make it non-nullable
    let model = selectedConfiguration.model!;

    const sasRequest = await fetch(`${root}/api/UploadModelFile`, {
      method: "POST",
      body: JSON.stringify({
        Model: model,
        Type: type
      })
    });

    if (sasRequest.ok) {
      const json = await sasRequest.json();
  
      const client = new BlockBlobClient(json.uri);
      const buffer = Buffer.from(file, 'utf-8');
      const uploadRequest = await client.uploadData(buffer);
      
      if (!uploadRequest.errorCode) {
        let key = `${type}_file_id`;

        // TODO: see if we can get rid of this step by making the upsert a partial update if possible
        const getFMFRequest = await getFanModelFile({ variables: { model } });

        const fmf = !!getFMFRequest.data?.fan_model_file.length
          ? getFMFRequest.data.fan_model_file[0]
          : {};

        // Not a partial update
        upsertFanModelFile({
          variables: {
            object: {
              ...fmf,
              model,
              is_safe: isSafe,
              [key]: `${model}-${type}.dxf`,
              id: undefined,
              __typename: undefined
            } as any
          },
          update: (cache) => cache.evict({ fieldName: "fan_model_file", broadcast: false })
        });

        toast.success("File uploaded successfully!");
        setFile(null);
        setConfigurationRequestCancelled(false);
        setSafe(undefined as unknown as boolean);
        setSelectedConfiguration(undefined as unknown as Configuration);
      }
    }

    setLoading(false);
  }, [file, type, selectedConfiguration, isSafe, getFanModelFile, upsertFanModelFile]);

  const renderConfigurationResult = React.useCallback((item: Configuration, index: number) => {
    return <tr key={`configuration-${index}`}>
      <th scope="row">{item.id}</th>
      <td>{item.model}</td>
      <td>{item.manufacturer}</td>
      <td>
        <Input
          type='checkbox'
          checked={(selectedConfiguration)?.id === item.id}
          onChange={() => {
            if ((selectedConfiguration)?.id === item.id) {
              setSelectedConfiguration(undefined as unknown as Configuration);
            }
            else {
              setSelectedConfiguration(item);
            }
          }}
        />
      </td>
    </tr>;
  }, [selectedConfiguration]);

  return (
    <Container className="mt-3">
      <div className="d-flex flex-column align-items-start mb-3">
        <h4>Upload Model File</h4>
        <Col
          className='d-flex flex-column align-items-start'
          lg={6}
          md={6}
          sm={6}
        >
          <div className="d-flex align-items-center justify-content-center w-100 mb-3 gap-3">
            <Input
              type="text"
              placeholder="Type fan model number..."
              value={model}
              innerRef={modelFieldRef}
              onChange={captureModel}
            />
            <Button
              className="p-2"
              disabled={loadingData}
              onClick={searchConfiguration}>
              {!loadingData && "Search"}
              {loadingData && <FontAwesomeIcon icon={faSpinner} spin/> }
            </Button>
          </div>
          {searchResultConfigurations.length === 0 && <p>No results found</p>}
          {searchResultConfigurations.length > 0 &&
            <React.Fragment>
              <h4>Results:</h4>
              <table className="table table-hover table-dark">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Model</th>
                    <th scope="col">Manufacturer</th>
                    <th scope="col" style={{width: 100}}>Selected</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResultConfigurations.map(renderConfigurationResult)}
                </tbody>
              </table>
              {!selectedConfiguration && <div className="d-flex align-items-center justify-content-end w-100 mb-3 gap-3">
                <span className="font-italic">Please select a configuration model to upload custom <strong>.dxf</strong> files for.</span>
              </div>}
            </React.Fragment>
          }
          {selectedConfiguration && (
            <React.Fragment>
              <h4>Select a model view:</h4>
              <Input
                className="mb-3"
                type='select'
                value={type}
                onChange={captureType}
              >
                <option value="front">Front</option>
                <option value="side">Side</option>
                <option value="top">Top</option>
                {/* Not sure if this is needed yet */}
                {/* <option value="control-panel">Control Panel</option> */}
              </Input>
              <Input
                className="mb-3"
                type="file"
                accept='.dxf'
                onChange={captureFile}
                innerRef={fileInputRef}
              />
              {fileHasError && <p className="text-danger">Please select a <strong>.dxf</strong> file.</p> }
              {file && <React.Fragment>
                <div className="d-flex align-items-center justify-content-end w-100 mb-3 gap-3">
                  <span className="font-weight-bold">Is this Fan Model safe to use?</span>
                  <div>
                    <input type="radio" value={"true"} name="option" onClick={() => setSafe(true)} /><span>Yes</span>
                  </div>
                  <div>
                    <input type="radio" value={"false"} name="option" onClick={() => setSafe(false)} /><span>No</span>
                  </div>
                </div>
              </React.Fragment>}
              <div className="d-flex align-items-center">
                <Button disabled={!file || !type || loading || fileHasError } onClick={upload}>Upload .dxf File</Button>
              </div>
            </React.Fragment>
          )}
        </Col>
      </div>
    </Container>
  );
};

export default UploadModelFiles;