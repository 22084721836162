import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Modal, Row } from 'reactstrap';
import { Configuration, Control_Panel_Sizing, Cost, Fan_Fit, Product, Product_Set_Input } from '../generated/graphql';
import FanCurveChart from './FanCurveChart';
import { RPMCoefficient } from '../helpers/file-checker';
import { findDesignCoefficients, findMaxCFM } from '../helpers/fan-curve';
import { calculateMCA, calculateMOCP, calculateFanArrayWeight, calculateRedundancy, calculateEfficiency, calculateRPMSoundPower, calculateOperatingInputHorserpower, calculateOperatingRPM, getCostBreakdown } from '../helpers/generate-configuration-query';
import './ExpandedConfigurationDetails.css';
import { generateQuoteDXF } from '../helpers/generate-quote.dxf';
import parseMoney from '../helpers/money-utils';
import { CostBreakdownTooltip } from './CostBreakdownTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPanelCost, getPanelSizeAndWeight, isSizingCustom } from '../helpers/calculate-custom-controls';

const soundPrettyOutput = (value: any) => {
  if (!value || value < 0)
    return "-";
  return value
}

// TODO: The table looks terrible - cleanup the style of the table when time allows
const ExpandedConfigurationDetails = ({
  product,
  configuration,
  fanCount,
  sizing,
  costs,
  fanFit,
  modal,
  expandedValid,
}: {
  product: Product_Set_Input,
  configuration: Configuration,
  fanCount: number | null,
  fanFit?: Fan_Fit,
  sizing?: Control_Panel_Sizing,
  costs?: Cost[],
  modal?: boolean,
  expandedValid: boolean,
  selectedConfigurationId?: number | null;
  selectedFanCount?: number | null;
}) => {
  const cleanFanCount = fanCount ?? 0;
  const fla = configuration?.fla_460 ?? configuration?.fla_400;

  const rawCoefficients = !!configuration?.rmp_coefficients
    ? JSON.parse(configuration?.rmp_coefficients) : {};

  let coefficients: RPMCoefficient[] = rawCoefficients
        ? Object.values(rawCoefficients)
        : [];

  coefficients = product.backdraft_dampers ?
    coefficients.map(c => Object.assign({}, c, {
      pressure: {
        a: c.pressure.a,
        b: c.pressure.b,
        c: c.pressure.c,
        d: c.pressure.d - 0.3
      }
    })) :
    coefficients;

  const cfm = product?.air_flow ?? 0;
  const tsp = product?.total_static_pressure ?? 0;

  const designCoefficients = findDesignCoefficients({
    cfm,
    tsp,
    coefficients,
    fanCount: cleanFanCount,
    model: configuration?.model
  });

  const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
    product.air_flow,
    designCoefficients.fanCount || 0,
    designCoefficients.lower.coefficient?.powerHp,
    designCoefficients.upper.coefficient?.powerHp,
    designCoefficients.interpolation
  ) : 0;
  const efficiency = calculateEfficiency({
    tsp,
    cfm,
    fanCount: cleanFanCount,
    horsepower,
  });

  const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
  const maxRPMCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM)[0];
  // console.log("tsp", tsp)
  const maxCFM = maxRPMCoefficients
    ? findMaxCFM({
      tsp,
      coefficient: maxRPMCoefficients.pressure,
    })
    : 0;
  // console.log("max_cfm", maxCFM)

  // console.log("fanCount", cleanFanCount)
  // console.log("design_cfm", cfm)
  const redundancy = calculateRedundancy({
    maxCFM,
    fanCount: cleanFanCount,
    designCFM: cfm,
  });

  const mca = calculateMCA({ fla, fanCount: cleanFanCount })
  const mocp = calculateMOCP({ fla, fanCount: cleanFanCount })

  // If sizing values dont equal actual values, need to calculate custom values
  // const customSizing = !(sizing?.amperage === mocp && sizing?.fan_count === cleanFanCount);
  const custom = isSizingCustom(cleanFanCount, mocp, [sizing], product.mas_ec_plus);
  const customSizeAndWeight = getPanelSizeAndWeight(cleanFanCount, mocp, [sizing], product.mas_ec_plus);
  const customCost = getPanelCost(cleanFanCount, mocp, [sizing], product.mas_ec_plus, product.outdoor_rating);

  const systemWeight = calculateFanArrayWeight({
    fanCount: cleanFanCount,
    fanWeight: !!configuration?.fan_weight ? configuration.fan_weight : 0 ,
    panelWeight: custom.sizing ? customSizeAndWeight.weight : sizing?.weight, 
    bulkheadHeight: product.height || 0,
    bulkheadWidth: product.width || 0,
  });

  const operatingSpeed = designCoefficients.upper ? calculateOperatingRPM(
    designCoefficients.upper.rpm ?? 0,
    designCoefficients.upper.tsp ?? 0,
    product.total_static_pressure,
  ) : 0;

  const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
    ? calculateRPMSoundPower(cleanFanCount, product.air_flow / cleanFanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
    : null;

  const unitCost = getCostBreakdown({
    fanCost: parseMoney(configuration?.price) ?? 0,
    fanCount: cleanFanCount ?? 1,
    controlPanelCost: custom.cost ? customCost : sizing?.cost, 
    bulkheadHeight: product.height ?? 0,
    bulkheadWidth: product.width ?? 0,
    costPerSQFT:
      parseMoney(costs?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value) ?? 0,
    miscFlatCost:
      parseMoney(costs?.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
    miscVariableCost:
      parseMoney(costs?.find((c) => c.name === "Misc_Variable:")?.value) ?? 0,
    hasTransducers: product.pressure_transducers ?? false,
    hasDampers: product.backdraft_dampers ?? false,
    damperCost:
      parseMoney(costs?.find((c) => c.name === "Backdraft_Damper")?.value) ?? 250,
  });

  const container = (
    <div className="config-details-container">
      <div className="details-content">
        <div className='tainer'>
          <div className="details-content-actions">
            <Button
              onClick={() => {
                fanFit && generateQuoteDXF({
                  fileName: `${product.tag}.dxf`,
                  config: configuration,
                  fanFitData: [fanFit],
                  products: [Object.assign({}, product, { configuration }) as Product],
                  sizings: [sizing]
                }).catch((e) => toast.error(e.message))
              }}>Download 2D CAD Drawing</Button>
              <div className="invalid-config-warning" hidden={expandedValid}>
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
                Warning: The current product settings are not valid for this configuration
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
              </div>
          </div>
          <Row className="mb-2">
            <Col lg="6" md="6" sm="6">
              <div className="stats-container">
                <div className="stat-row-container">
                  <div className="state-row-label">
                    <span>Cost
                      <CostBreakdownTooltip unitCost={unitCost} />
                    </span>
                  </div>
                  <div>
                    <span>${unitCost.totalCost.toLocaleString()}</span>
                  </div>
                </div>
                <StatRow label="Fan Model" value={configuration?.model} symbol='' />
                <StatRow label="Fan Count" value={cleanFanCount} symbol='' />
                <StatRow label="Efficiency" value={(efficiency * 100).toFixed(2)} symbol="%" />
                <StatRow label="Redundancy" value={Math.round(Math.max(0.01, (redundancy * 100)))} symbol="%" />
                <StatRow label="Motor Power" value={configuration?.nominal_hp} symbol="HP" />
                <StatRow label="Input Power" value={horsepower.toFixed(2)} symbol="HP" />
                <StatRow label="Operating Speed" value={operatingSpeed} symbol="RPM" />
                <StatRow label="Maximum Speed" value={maxRPM} symbol="RPM" />
                <StatRow label="System Weight" value={Math.round(systemWeight)} symbol="Lbs" />
                <StatRow label="Fan Weight" value={Math.round(configuration?.fan_weight ?? 0)} symbol="Lbs" />
                <StatRow label="Fan Blade Diameter" value={configuration?.blade_diameter} symbol="MM" />
                <StatRow label="Fan Depth" value={configuration?.depth} symbol="IN" />
                <StatRow label="Control Panel Width" value={custom.sizing ? customSizeAndWeight.width : sizing.width} symbol="IN" />
              </div>
              <h3>Electrical Data</h3>
              <div className="stats-container">
                <StatRow label="FLA" value={fla} symbol="A" />
                <StatRow label="MCA" value={mca?.toFixed(2)} symbol="A" />
                <StatRow label="MOCP" value={Math.round(mocp)} symbol="A" />
              </div>
            </Col>
            <Col lg="6" md="6" sm="6" className="d-flex flex-column justify-content-between align-items-end">
              <div style={{ height: '420px', width: '100%' }}>
                <FanCurveChart
                  tsp={tsp}
                  cfm={cfm}
                  fanCount={cleanFanCount}
                  model={configuration?.model}
                  coefficients={coefficients}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ overflowX: "auto" }}>
              <h2>System Sound Power</h2>
              <table className="sytem-sound-power-table">
                <tr>
                  <th className="center">
                    <div className="sytem-sound-power-table-header center">
                      Type
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      63 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      125 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      250 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      500 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      1,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      2,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      4,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      8,000 Hz
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header type">
                      Radiated
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.radiated.toFixed(0))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header center">
                      Unit Discharge
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header center">
                      Unit Return
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {modal
        ? (
          <Modal isOpen={!!configuration} style={{ width: '70vw', minWidth: '1000px' }}>
            {container}
          </Modal>
        )
        : (
          <Col
            hidden={!configuration}
            className="expanded-configuration-details-container"
          >
            {container}
          </Col>
        )}
    </>
  );
};

// const StatsHeader = ({ label }: { label: string }) => (
//   <div className='stats-header'>
//     <span>
//       {label}
//     </span>
//   </div>
// );

const StatRow = ({
  label,
  value,
  symbol,
}: { label: string, value: any, symbol: string }) => (
  <div className="stat-row-container">
    <div className="state-row-label">
      <span>{label}</span>
    </div>
    <div>
      <span>{value} {symbol}</span>
    </div>
  </div>
);

export default ExpandedConfigurationDetails;