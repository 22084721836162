export const validatePrecision = (
  number: number | undefined,
  maxPlaces: number
) => {
  if (number === undefined || number === null || isNaN(number)) return false; 
  if (number === 0) return true;
  if (!number) return false;
  const str = String(number || "0").split(".");
  if (str.length < 2) return true;
  return str[1].length <= maxPlaces;
};

export const validateAirflow = (value: number | undefined) => validatePrecision(value, 1000); // precision is unspecified
export const validatePressure = (value: number | undefined) =>
  validatePrecision(value, 2);
export const validateWidth = (value: number | undefined) =>
  validatePrecision(value, 1);
export const validateHeight = (value: number | undefined) =>
  validatePrecision(value, 1);

const validateProducts = {
  validateAirflow,
  validatePrecision,
  validatePressure,
  validateWidth,
  validateHeight,
};

export default validateProducts;
