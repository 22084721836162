import { format } from 'date-fns';

export const formatDate = (date: string | Date) => format(
  new Date(date),
  "MM/dd/yyyy"
);

export const formatDateISO = (date: string | Date) => format(
  new Date(date),
  "yyyy-MM-dd"
);

export const formatLongDate = (date: string | Date) => format(
  new Date(date),
  "MMMM dd, yyyy"
);

export const formatDateTime = (dateTime: string | Date) => format(new Date(dateTime), 'MMM d yyyy')
  + " at "
  + format(new Date(dateTime), 'h:mm a');

const dateUtils = {
  formatDate,
  formatDateISO,
  formatLongDate,
  formatDateTime,
};

export default dateUtils;