import { useState } from "react";
import { Container, Button, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as EmailValidator from 'email-validator';

import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import { User } from "../types";
import AddUserModal from "../components/AddUserModal";
import MHTable from '../components/MHTable';

const Users = () => {
  const users: User[] = [
    {
      firstName: 'Drake',
      lastName: 'Siemer',
      email: 'drake.siemer@inventive.io',
      role: {
        name: 'admin'
      }
    } as User
  ];

  // TODO: Capture info and update the user
  const updateUser = () => {};

  const columnHelper = createColumnHelper<User>();

  const table = useReactTable(
    {
      columns: [
        columnHelper.accessor('firstName', {
          header: () => <span>First Name</span>,
        }),
        columnHelper.accessor('lastName', {
          header: () => <span>Last Name</span>,
        }),
        columnHelper.accessor('email', {
          header: () => <span>Email</span>,
        }),
        columnHelper.accessor('role', {
          header: () => <span>Role</span>,
        }),
        columnHelper.display({
          id: 'actions',
        })
      ],
      data: users ?? [],
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    }
  );

  return (
    <Container className="mt-3">
      <div className="d-flex">
        <h3>Users</h3>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <AddUserModal onSuccess={() => {}} />
      </div>
      <MHTable
        table={table}
        mapRow={row => <UserRow key={row.id} user={row.original} updateUser={updateUser} />}
      />
    </Container>
  );
};

const UserRow = ({ user, updateUser }: { user: User, updateUser: Function }) => {
  const [editMode, setEditMode] = useState(false);
  const [details, setDetails] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.role?.name
  });
  const isValid = !!details.firstName
    && !!details.lastName
    && !!details.role
    && !!details.email
    && EmailValidator.validate(details.email);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => setDetails({
    ...details,
    [e.target.name]: e.target.value
  });

  const onCancel = () => {
    setDetails({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role?.name
    });

    setEditMode(false);
  }

  return (
    <>
      {editMode
        ? (
          <tr>
            <td><Input name="firstName" placeholder="First Name" value={details.firstName} onChange={onInput} /></td>
            <td><Input name="lastName" placeholder="Last Name" value={details.lastName} onChange={onInput} /></td>
            <td><Input name="email" placeholder="Email" value={details.email} onChange={onInput} /></td>
            <td>
              <Input type='select' name="role" value={details.role} onChange={onInput}>
                <option value=''>- Select -</option>
                <option value='admin'>Admin</option>
                <option value='user'>User</option>
              </Input>
            </td>
            <td>
              <div className="d-flex justify-content-around">
                <Button onClick={() => onCancel()}>Cancel</Button>
                <Button disabled={!isValid}>Save</Button>
              </div>
            </td>
          </tr>)
        : (
          <tr>
            <th>{user.firstName}</th>
            <th>{user.lastName}</th>
            <th>{user.email}</th>
            {/* TODO: Role Dropdown and update user */}
            {/* TODO: Check if the user has the right role that allows them to do that */}
            <th>{user.role?.name}</th>
            {/* TODO: Hook this button up */}
            <th>
                <div className="d-flex justify-content-around">
                  <button className='btn btn-link' onClick={() => setEditMode(true)}>Edit <FontAwesomeIcon icon='pencil' /></button>
                  <button className='btn btn-link' onClick={() => {}}>Delete <FontAwesomeIcon icon='trash' /></button>
                </div>
            </th>
          </tr>)}
    </>
  );
};

export default Users;
