import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

const DeleteEntityModal = ({
  entity,
  isOpen,
  pending,
  onConfirm,
  onCancel,
}: {
  entity: string,
  isOpen: boolean,
  pending?: boolean,
  onConfirm: Function,
  onCancel: Function,
}) => (
  <Modal isOpen={isOpen} toggle={() => onCancel()}>
    <ModalBody>
      <h3>Are you sure?</h3>
      <p>Do you really want to delete this {entity}?</p>
    </ModalBody>
    <ModalFooter className="px-2">
      <Button onClick={() => onCancel()}>Cancel</Button>
      <Button
        style={{ backgroundColor: 'red', borderColor: 'red' }}
        disabled={pending}
        onClick={() => onConfirm()}
      >Confirm</Button>
    </ModalFooter>
  </Modal>
);

export default DeleteEntityModal;
