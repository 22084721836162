import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from 'chartjs-plugin-annotation';

export const resetHiddenCanvas = () => {
  const chartContainer = document.getElementById('canvas-container');
  if (!chartContainer) return;
  chartContainer.style.display = 'hidden';
  chartContainer.style.overflow = 'hidden';
  chartContainer.style.position = 'absolute';
  chartContainer.style.left = '-5000';
  chartContainer.style.height = '600px';
  chartContainer.style.width = '1200px';
  const canvas = chartContainer.firstElementChild;
  if (!canvas) return;
  chartContainer?.removeChild(canvas);

  const newCanvas = document.createElement('canvas');
  newCanvas.id = 'hidden-canvas';

  chartContainer?.appendChild(newCanvas);
};

export const drawFanCurveChart = (datasets: any[], annotation: Object) => {
  resetHiddenCanvas();

  const canvas = (document.getElementById('hidden-canvas') as any);
  canvas.style.display = 'block';

  const chart = new Chart((canvas as any).getContext("2d"), {
    type: 'scatter',
    options: {
      showLine: true,
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false
        },
        annotation
      },
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        y: {
          min: 0,
          beginAtZero: true,
          title: {
            text: 'TSP (inwc)'
          },
        },
        x: {
          min: 0,
          type: 'linear',
          position: 'bottom',
          title: {
            text: 'Airflow (CFM)'
          },
        }
      },
    } as any,
    data: { datasets },
    plugins: [ChartDataLabels, AnnotationPlugin]
  });

  return { chart, image: chart.toBase64Image() };
};