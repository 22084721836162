import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { useInsertProductMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const AddProductModal = ({
  jobId,
  quoteId,
  isOpen = true,
  toggle,
  onSuccess
}: {
  jobId: number,
  quoteId: number,
  isOpen: boolean,
  toggle: Function,
  onSuccess: Function,
}) => {
  const [productDetails, setProductDetails] = useState({
    tag: "",
    air_flow: 0,
    total_static_pressure: 0,
    width: 0,
    height: 0,
    power: 460,
    backdraft_dampers: false,
    over_one_hundred_percent_redundancy: false,
    pressure_transducers: false,
    mas_ec_plus: false,
    outdoor_rating: null,
    job_id: jobId,
    quote_id: quoteId,
  });
  const [
    insertProduct
  ] = useInsertProductMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onCreate = () => {
    if (!isValid || isLoading)
      return;
    setIsLoading(true);
    insertProduct({
      variables: { object: productDetails },
      onCompleted: () => { onSuccess(); }
    });
    setIsLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value: any = "";
    if (e.target.name === "outdoor_rating"){
      if (e.target.value === "indoor") value = false;
      if (e.target.value === "outdoor") value = true;
    } else if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      try {
        value = parseFloat(e.target.value);
      } catch (error) {
        console.error("Parse error: ", error);
        return;
      }
    } else {
      value = e.target.value;
    }

    setProductDetails({ ...productDetails, [e.target.name]: value });
  };


  const validTag = productDetails.tag.length > 0;
  const validAirflow = productDetails.air_flow > 0;
  const validHeight = productDetails.height > 0;
  const validWidth = productDetails.width > 0;
  const validPressure = productDetails.total_static_pressure > 0;

  const isValid = (
    validTag &&
    validAirflow &&
    validHeight &&
    validWidth &&
    validPressure
  );

  useEffect(() => {
    setProductDetails({
      tag: "",
      air_flow: 0,
      total_static_pressure: 0,
      width: 0,
      height: 0,
      power: 460,
      backdraft_dampers: false,
      over_one_hundred_percent_redundancy: false,
      pressure_transducers: false,
      mas_ec_plus: false,
      outdoor_rating: null,
      job_id: productDetails.job_id,
      quote_id: productDetails.quote_id,
    });
  }, [isOpen, productDetails.job_id, productDetails.quote_id]);

  return (<>
    <Modal isOpen={isOpen} toggle={() => toggle()} backdrop={"static"}>
      <Form onSubmit={(e) => { e.preventDefault(); onCreate() }}>
        <div className="modal-header">
          <h3>Add new product</h3>
          <FontAwesomeIcon
            style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
            onClick={() => { if (!isLoading) toggle(); }}
            icon={faXmarkCircle} />
        </div>
        <ModalBody>
          <div>
            <div className="box-label">Tag</div>
            <FormGroup>
              <Input
                name="tag"
                value={productDetails.tag || ""}
                onChange={handleChange}
                invalid={!validTag}
              />
              {/* <FormFeedback>Tag is required</FormFeedback> */}
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Air Flow (CFM)</div>
            <FormGroup>
              <Input
                name="air_flow"
                type="number"
                min={0}
                value={productDetails.air_flow}
                onChange={handleChange}
                invalid={!validAirflow}
              />
              {/* <FormFeedback>Air Flow is required and must be positive</FormFeedback> */}
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Total SP</div>
            <FormGroup>
              <Input
                name="total_static_pressure"
                type="number"
                value={productDetails.total_static_pressure}
                onChange={handleChange}
                invalid={!validPressure}
              />
              {/* <FormFeedback>2 decimal place only</FormFeedback> */}
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Width</div>
            <FormGroup>
              <Input
                name="width"
                type="number"
                value={productDetails.width}
                onChange={handleChange}
                invalid={!validWidth}
              />
              {/* <FormFeedback>1 decimal place only</FormFeedback> */}
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Height</div>
            <FormGroup>
              <Input
                name="height"
                type="number"
                value={productDetails.height}
                onChange={handleChange}
                invalid={!validHeight}
              />
              {/* <FormFeedback>1 decimal place only</FormFeedback> */}
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Power</div>
            <Input
              name="power"
              type="select"
              value={productDetails.power}
              onChange={handleChange}
            >
              <option value={460}>460V/3~/60HZ</option>
              <option value={208}>208V/3~/60HZ</option>
            </Input>
          </div>
          <div className="options-inputs">
            <div>
              <label className="box-label">
                <Input
                  name="backdraft_dampers"
                  type="checkbox"
                  checked={productDetails.backdraft_dampers || false}
                  onChange={handleChange}
                /> Backdraft Dampers</label>
            </div>
            <div>
              <label className="box-label">
                <Input
                  name="over_one_hundred_percent_redundancy"
                  type="checkbox"
                  checked={productDetails.over_one_hundred_percent_redundancy || false}
                  onChange={handleChange}
                /> Over 100% Redundancy?</label>
            </div>
            <div>
              <label className="box-label">
                <Input
                  name="pressure_transducers"
                  type="checkbox"
                  checked={productDetails.pressure_transducers || false}
                  onChange={handleChange}
                /> Pressure Transducers</label>
            </div>
            <div>
              <label className="box-label">
                <Input
                  name="mas_ec_plus"
                  type="checkbox"
                  checked={productDetails.mas_ec_plus || false}
                  onChange={handleChange}
                /> MAS EC+</label>
            </div>
            <div className={!!productDetails.mas_ec_plus ? "collapse-open" : "collapse-closed"}>
              <div className="box-label">Indoor/Outdoor</div>
              <Input
                name="outdoor_rating"
                type="select"
                value={productDetails.outdoor_rating ? "outdoor" : "indoor"}
                onChange={handleChange}
              >
                <option value={"indoor"}>Indoor</option>
                <option value={"outdoor"}>Outdoor</option>
              </Input>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start mx-2">
          <Button disabled={isLoading || !isValid} type='submit'>
            Create product
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  </>);
};

export default AddProductModal;