import { Link } from "react-router-dom";
import "./Tools.css"
import Flats from "./Flats";
import Calculator from "./Calculator";
import Search from "./Search";

export function ToolsPage({ route }: { route?: string }) {
    return (<div className="page tools">
        <div className="tools-sidebar">
            <Link to="/tools/calculator" className={route === "calculator" ? "active" : ""}>Calculator</Link>
            <Link to="/tools/flats" className={route === "flats" ? "active" : ""}>Flats</Link>
            <Link to="/tools/search" className={route === "search" ? "active" : ""}>Advanced Search</Link>
        </div>
        {route === "search" &&
            <Search />
        }
        {route === "flats" &&
            <Flats />
        }
        {route === "calculator" &&
            <Calculator />
        }
    </div >)
}