import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

export function CostBreakdownTooltip({ unitCost, fontSize, id }:{ unitCost: any, fontSize?: number, id?: string }) {
    id = id || 'cost-breakdown';

    return <>
        <FontAwesomeIcon
            id={id}
            style={{ fontSize: fontSize || 16, cursor: "pointer", paddingLeft: "5px" }}
            icon={faInfoCircle} />
        <UncontrolledTooltip placement='left' target={id} style={{ outline: "none" }}>
            <table style={{ textAlign: "left" }}>
                <tbody>
                    <tr><td>Fans</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.totalFanCost).toLocaleString()}`}</td></tr>
                    <tr><td>Freight (Fans)</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.freightFanCost).toLocaleString()}`}</td></tr>
                    <tr><td>Controls</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.controlPanelCost).toLocaleString()}`}</td></tr>
                    <tr><td>Freight (Controls)</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.freightControlPanelCost).toLocaleString()}`}</td></tr>
                    <tr><td>Bulkhead</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.bulkheadCost).toLocaleString()}`}</td></tr>
                    <tr><td>Consumables</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.miscCost).toLocaleString()}`}</td></tr>
                    <tr><td>Options</td><td className='cost-overview-value'>${`${Math.ceil(unitCost.optionsCost).toLocaleString()}`}</td></tr>
                    <tr className="final-row"><td><strong>Total</strong></td><td className='cost-overview-value'><strong>${`${Math.ceil(unitCost.totalCost).toLocaleString()}`}</strong></td></tr>
                </tbody>
            </table>
        </UncontrolledTooltip>
    </>
}