import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { getCubicRoots } from 'cubic-roots';
import { Coefficient, RPMCoefficient } from '../helpers/file-checker';
import { findDesignCoefficients, findDesignPoint, generateCutoffCurve, generateRPMCurve, generateSystemCurve } from '../helpers/fan-curve';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin
);

const cutoffCoefficients: { [key: string]: Coefficient } = {
  "N88-50701": {
    a: 2.4921e-12,
    b: 5.6495e-7,
    c: 6.5708e-5,
    d: -5.7739e-2
  },
  "N88-56700": {
    a: 2.5035e-12,
    b: 3.7278e-7,
    c: 5.6808e-5,
    d: -4.4526e-2
  },
  "N88-56708": {
    a: 1.422e-11,
    b: 2.7605e-7,
    c: 3.158e-4,
    d: -2.6258e-1
  }
};

// TODO: Update the other line points to include a calculated number?
const FanCurveChart = ({
  cfm,
  tsp,
  fanCount,
  coefficients,
  model,
  minimum = false
}: {
  cfm: number,
  tsp: number,
  fanCount: number,
  coefficients: RPMCoefficient[],
  model?: string,
  minimum?: boolean
}) => {
  if(!coefficients.length) {
    return (
      <Scatter
        data={{ datasets: [] }}
        key={`${model}:${fanCount}`}
      />
    );
  }

  const roots = getCubicRoots(
    coefficients[0].pressure.a,
    coefficients[0].pressure.b,
    coefficients[0].pressure.c,
    coefficients[0].pressure.d
  ).map((r: any) => r.real) // this can be a complex number, so we only want the real parts

  let positiveRoots = roots.filter((root: any) => root >= 0 && root !== Infinity).sort((a: number, b: number) => a - b);
  const root = positiveRoots[0];

  const chartOptions = {
    showLine: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false
      },
      annotation: { annotations: {} }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      y: {
        min: 0,
        max: 0,
        beginAtZero: true,
        title: {
          display: !minimum,
          text: 'TSP (inwc)'
        },
        ticks: {
          display: !minimum,
          stepSize: 1,
          callback: function (value: any) { if (Number.isInteger(value)) { return value; } },
          precision: 0
        }
      },
      x: {
        min: 0,
        max: 0,
        type: 'linear',
        position: 'bottom',
        title: {
          display: !minimum,
          text: 'Airflow (CFM)'
        },
        ticks: {
          display: !minimum,
          callback: 
            function (value: number, index: number, ticks: any) 
            { 
              if (index === 0) { 
                let tickDiff = ticks[1].value - ticks[0].value;
                let biggestTick = ticks[ticks.length-2].value;
                ticks.push({value: biggestTick + tickDiff, label: biggestTick + tickDiff}); 
              } 
              if (Number.isInteger(value) && value % 10 === 0) { return value; } 
            },
          precision: 0,
        },
        grid: {
          display: !minimum,
          drawOnChartArea: false,
        }
      }
    }
  };

  const designCoefficients = findDesignCoefficients({
    cfm,
    tsp,
    fanCount,
    coefficients,
    model
  });
  const maxRPM = Math.max(...(Object.values(coefficients).map((k) => k.RPM)));
  const maxRPMCoefficients = coefficients.find((c: RPMCoefficient) => c.RPM === maxRPM)
  const designRPM = designCoefficients.lower.rpm
    ? designCoefficients.lower.rpm * Math.sqrt(Math.abs(designCoefficients.lower.tsp! / designCoefficients.upper.tsp!))
    : 0;

  const rpmCurves = !!coefficients.length
    ? coefficients.map(c => generateRPMCurve({ fanCount, rpmCoefficient: c, points: 25, minimum }))
    : [];

  const designPoints = 25;
  const designRPMCurve = !!designRPM
    ? generateRPMCurve({
      fanCount,
      rpmCoefficient: {
        RPM: Math.round(designRPM),
        pressure: designCoefficients.coefficient,
      } as RPMCoefficient,
      dashed: true,
      points: designPoints,
      label: false,
      minimum
    })
    : null;

  const systemCurve = maxRPMCoefficients
    ? generateSystemCurve({
      cfm,
      tsp,
      fanCount,
      points: designPoints,
      maxRpmPressureCoefficients: maxRPMCoefficients.pressure,
    })
    : null;

  const designPoint = maxRPMCoefficients && systemCurve && findDesignPoint(designCoefficients.coefficient, systemCurve?.coefficients, cfm, tsp, fanCount);

  let cutoff = model ? cutoffCoefficients[model] : undefined;
  const cutoffCurve = (cutoff && maxRPMCoefficients)
    ? generateCutoffCurve({
      fanCount,
      cutoffCoefficients: cutoff,
      maxRpmPressureCoefficients: maxRPMCoefficients.pressure,
    })
    : null;

  if (!!designPoint) {
    const annotations = chartOptions.plugins.annotation.annotations;

    (annotations as any).designPoint = {
      type: 'point',
      backgroundColor: '#1890cb',
      radius: 5,
      xValue: designPoint.x,
      yValue: designPoint.y
    };
  }

  // the "top" line curve contains the x intercept that we need to set the scaling for the chart
  // find the cubic root to find the x intercepts
  if (designRPMCurve) rpmCurves.push(designRPMCurve);
  if (systemCurve) rpmCurves.push(systemCurve as any);
  if (cutoffCurve) rpmCurves.push(cutoffCurve as any);

  chartOptions.scales.x.max = ((root) * fanCount) * 1.05; // use the max x intercept to set the max x scale
  chartOptions.scales.y.max = coefficients[0].pressure.d + 0.5;

  return (
    <Scatter
      options={chartOptions as any}
      data={{ datasets: rpmCurves as any }}
      plugins={[ChartDataLabels]}
      key={`${model}:${fanCount}`}
    />
  );
};

export default FanCurveChart;