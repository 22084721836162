import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import featuredItems from "../config/featuredFans.json"

import {
  Configuration,
  Control_Panel_Sizing,
  Product_Set_Input,
} from "../generated/graphql";

import FanCurveChart from "./FanCurveChart";

import { DesignParameters } from "../helpers/fan-curve";
import { RPMCoefficient } from "../helpers/file-checker";
import { formatMoney } from "../helpers/money-utils";

import "./ConfigurationCard.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ConfigurationCard = ({
  product,
  configuration,
  designIteration,
  controlPanel,
  mca,
  mocp,
  unitCost,
  arrayWeight,
  inputPower,
  redundancy,
  efficiency,
  inletSound,
  outletSound,
  selectedConfigurationId,
  selectedFanCount,
  onSelect,
  onExpand,
}: {
  product: Product_Set_Input;
  configuration: Configuration;
  designIteration: DesignParameters;
  controlPanel: Control_Panel_Sizing | undefined;
  mca: number;
  mocp: number;
  inputPower: number;
  redundancy: number;
  efficiency: number;
  inletSound: number;
  outletSound: number;
  unitCost?: number;
  arrayWeight?: number;
  selectedConfigurationId: number | null;
  selectedFanCount: number | null;
  onSelect: (configuration: Configuration | null, fanCount: number | null) => void;
  onExpand: Function;
}) => {
  const designTsp = product?.total_static_pressure ?? 0;
  const fla = configuration.fla_460 ?? configuration.fla_400;

  const rawCoefficients = !!configuration?.rmp_coefficients
    ? JSON.parse(configuration?.rmp_coefficients)
    : null;

  let coefficients: RPMCoefficient[] = rawCoefficients
    ? Object.values(rawCoefficients)
    : [];

  coefficients = product.backdraft_dampers ?
    coefficients.map(c => Object.assign({}, c, {
      pressure: {
        a: c.pressure.a,
        b: c.pressure.b,
        c: c.pressure.c,
        d: c.pressure.d - 0.2
      }
    })) :
    coefficients;

  const selected =
    selectedConfigurationId === configuration.id &&
    selectedFanCount === designIteration.fanCount;

  return (
    <Card className={`configuration-card ${selected ? 'selected' : ''}`}>
      <div className="configuration-header">
        {featuredItems.indexOf(configuration.model) >= 0 && (<span className="featured-item">
          <FontAwesomeIcon icon={faStar} />
        </span>)}
        <span className="product-model">
          {designIteration.fanCount} - {configuration.model}
        </span>
        <span className="product-cost">
          {formatMoney(unitCost ?? 0)}
        </span>
        <button className="basic" onClick={() => onExpand({
          configuration,
          sizing: controlPanel,
          fanCount: designIteration.fanCount
        })}>Inspect</button>
        <button
          className={selected ? 'selected' : 'not-selected'}
          onClick={() => 
            selected
              ? onSelect(null, null)
              : onSelect(configuration, designIteration.fanCount)
          }
        >
          <span>
            {selected && <FontAwesomeIcon icon="check" className="ps-1" />}
            {selected ? " Selected" : "Select"}
          </span>
        </button>
      </div>
      <CardBody className="d-flex configuration-body">
        <Col
          lg="7"
          md="7"
          sm="7"
          className="d-flex flex-column justify-content-between configuration-content"
        >
          <div className="d-flex flex-column justify-content-around configuration-details-container">
            <div className="d-flex justify-content-between configuration-details">
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>EFFICIENCY</p>
                <b>{(efficiency * 100)?.toFixed(2)}%</b>
              </div>
              <div className="configuration-detail-item">
                <p>FLA</p>
                <b>{fla?.toFixed(2)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>REDUNDANCY</p>
                <b>{Math.round(Math.max(0.01, (redundancy * 100)))}%</b>
              </div>
              <div className="configuration-detail-item">
                <p>MCA</p>
                <b>{mca?.toFixed(2)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>WEIGHT</p>
                <b>
                  {Math.round(arrayWeight ?? 0)}{" "}
                  LBS
                </b>
              </div>
              <div className="configuration-detail-item">
                <p>MOCP</p>
                <b>{Math.round(mocp)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>MOTOR POWER</p>
                <b>{configuration.nominal_hp.toFixed(2) || 0} HP</b>
              </div>
              <div className="configuration-detail-item">
                <p>INLET SOUND</p>
                <b>{inletSound.toFixed(2) || 0}</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>INPUT POWER</p>
                <b>{inputPower.toFixed(2)} HP</b>
              </div>
              <div className="configuration-detail-item">
                <p>OUTLET SOUND</p>
                <b>{outletSound.toFixed(2) || 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg="5"
          md="5"
          sm="5"
          className="d-flex flex-column justify-content-between configuration-actions"
        >
          <div style={{ height: "150px" }}>
            <FanCurveChart
              tsp={designTsp}
              cfm={product?.air_flow ?? 0}
              fanCount={designIteration.fanCount}
              coefficients={coefficients}
              model={configuration.model}
              minimum
            />
          </div>
          <div className="d-flex justify-content-between configuration-details">
            <div className="configuration-detail-item">
              <p>BLADE DIAMETER</p>
              <b>{configuration.blade_diameter} MM</b>
            </div>
            <div className="configuration-detail-item">
              <p>FAN DEPTH</p>
              <b>{configuration.depth} IN</b>
            </div>
          </div>
        </Col>
      </CardBody>
    </Card>
  );
};

export default ConfigurationCard;
