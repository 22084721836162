import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from "@react-keycloak/web";
import "./Header.css"
import { ReactComponent as MasLogo } from "../assets/logo.svg"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const toggle = () => setIsOpen((prevState) => !prevState);

  const routes = [
    'Profile',
    'Users',
    'Jobs',
    'Upload Configurations',
    'Upload Model Files'
  ];

  const currentPathname = window.location.pathname;
  const dropdownItems = routes.map(r => {
    const route = `/${r.toLowerCase().split(' ').join('-')}`;
    const active = route === currentPathname;

    return (
      <DropdownItem
        key={r}
        active={active}
        onClick={() => navigate(route)}
      >
        {r}
      </DropdownItem>
    );
  });

  return !keycloak.authenticated
    ? <></>
    : (<div className="header">
      <div className='flex selector'>
        <a href="/jobs">
          <div className='logo'>
            <MasLogo />
          </div>
        </a>
        <div className="app-selector">
          <span className="title">
            <h1>Leo</h1>
            <h3>MAS EC Selections</h3>
          </span>
          <span className="down-arrow">
            <FontAwesomeIcon style={{ fontSize: 25 }} icon={faCaretDown} />
          </span>
        </div>
        <Link to="/jobs">Jobs</Link>
        <Link to="/tools">Tools</Link>
      </div>
      <div className="flex">
        <span className="user-name">
          {(keycloak.userInfo as any)?.name}
        </span>
        <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
          <DropdownToggle>
            <FontAwesomeIcon icon="bars" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem key='logout' onClick={() => keycloak.logout()}>Logout</DropdownItem>
            {dropdownItems}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>);
};

export default Header;