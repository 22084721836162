import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Col, Row, Container } from "reactstrap";

import './Entry.css';

const Entry = () => {
  const { keycloak } = useKeycloak();

  return (
    <Container className="entry-container mh-100">
      <Row>
        <Col>
          <button className="btn" onClick={() => keycloak.login()}>Log In</button>
        </Col>
      </Row>
    </Container>
  );
};

export default Entry;