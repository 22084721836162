import React, { ReactNode } from 'react';
import * as ReactStrap from 'reactstrap';

import {
  Table,
  HeaderGroup,
  Row,
  flexRender,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// TODO: figure out how to make this work with server side pagination
const Pagination = ({ table }: { table: Table<any> }) => (
  <ReactStrap.Container style={{ width: '100%' }}>
    <div className="h-2" />
    <div className="d-flex justify-content-center">
      <ReactStrap.Col />
      <ReactStrap.Col>
        <div className="d-flex">
          <div className="d-flex">
            <button
              className="btn"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon='chevron-left' />
              <FontAwesomeIcon icon='chevron-left' />
            </button>
            <button
              className="btn"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon='chevron-left' />
            </button>
          </div>
          <span className="flex items-center gap-1 mx-2">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <div className="d-flex">
            <button
              className="btn"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon='chevron-right' />
            </button>
            <button
              className="btn"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon='chevron-right' />
              <FontAwesomeIcon icon='chevron-right' />
            </button>
          </div>
        </div>
      </ReactStrap.Col>
      <ReactStrap.Col>
        <ReactStrap.Input
          type="select"
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </ReactStrap.Input>
      </ReactStrap.Col>
    </div>
  </ReactStrap.Container>
);

const MHTable = ({
  table,
  pagination = true,
  mapHeader,
  mapRow,
  appendedRow
}: {
  table: Table<any>,
  pagination?: boolean,
  mapHeader?: (value: HeaderGroup<any>, index: number, array: HeaderGroup<any>[]) => any,
  mapRow?: (value: Row<any>, index: number, array: Row<any>[]) => ReactNode,
  appendedRow?: ReactNode
}) => (
  <>
    <ReactStrap.Table bordered>
      <thead>
        {table.getHeaderGroups().map(mapHeader != null ? mapHeader : headerGroup => (
          <tr key={headerGroup.id} style={{ borderWidth: '1px' }}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(mapRow != null ? mapRow : row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
        {appendedRow && appendedRow}
      </tbody>
    </ReactStrap.Table>
    {pagination && <Pagination table={table} />}
  </>
);

export default MHTable;