import React from "react";
import { useParams } from "react-router-dom";

import {
  Job,
  useGetJobByIdQuery,
  useGetQuotesByJobIdQuery,
} from "../generated/graphql";

import QuotesTable from "../components/Quotes";

import "./Job.css";

const JobComponent = () => {
  const params = useParams();
  const id = parseInt(params?.id!);

  const { data } = useGetJobByIdQuery({
    variables: { id },
  });

  const quotesQuery = useGetQuotesByJobIdQuery({ variables: { jobId: id } });

  if (!data?.job_by_pk)
    return <div />

  return (
    <div className="page">
      <QuotesTable
        job={data?.job_by_pk as Job}
        quotesQuery={quotesQuery}
      />
    </div>
  );
};

export default JobComponent;
