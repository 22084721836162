import React from "react";
import ReactDOM from "react-dom/client";

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faGear,
  faTrash,
  faPencil,
  faSave,
  faClone,
  faAngleUp,
  faAngleDown,
  faUndo,
  faCheck,
  faQuestion,
  faUpRightAndDownLeftFromCenter,
  faTriangleExclamation,
  faChevronLeft,
  faChevronRight,
  faArrowTurnUp
} from '@fortawesome/free-solid-svg-icons'

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.3.1";

import "./index.css";

// Globally Accessible Icons
library.add(
  faBars,
  faGear,
  faTrash,
  faPencil,
  faSave,
  faClone,
  faAngleUp,
  faAngleDown,
  faUndo,
  faCheck,
  faQuestion,
  faUpRightAndDownLeftFromCenter,
  faTriangleExclamation,
  faChevronLeft,
  faChevronRight,
  faArrowTurnUp
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReactKeycloakProvider authClient={keycloak}
  onTokens={({ token }) => {
    if (!token) return;
    localStorage.setItem("token", token.toString());
  }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
