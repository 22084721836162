import React from "react";
import { Navigate } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";

import client from "../apollo";
import { NON_AUTHORIZED_REDIRECT_URL } from "../constants";

const PrivateRoute = ({ children }: { children: any }) => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) return <></>;
  if (!keycloak.authenticated)
    return <Navigate to={NON_AUTHORIZED_REDIRECT_URL} />;
  
  if (initialized && !keycloak.userInfo) keycloak.loadUserInfo();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default PrivateRoute;
