import { getCubicRoots } from 'cubic-roots';
import { Coefficient, RPMCoefficient } from "./file-checker"

export interface DesignParameters {
  fanCount: number,
  upper: {
    tsp: number | null,
    rpm: number | null,
    coefficient: RPMCoefficient | undefined,
  },
  lower: {
    tsp: number | null,
    rpm: number | null,
    coefficient: RPMCoefficient | undefined,
  },
  coefficient: Coefficient,
  interpolation: number,
}

export const calculateTsp = ({
 cfm,
 fanCount,
 coefficient,
}: {
  cfm: number,
  fanCount: number,
  coefficient: Coefficient
}) => (coefficient.a * Math.pow((cfm / fanCount), 3))
  + (coefficient.b * Math.pow((cfm / fanCount), 2))
  + (coefficient.c * Math.pow((cfm / fanCount), 1))
  + coefficient.d;

export const calculateSingleCoefficient = ({
  tsp,
  tspUB,
  tspLB,
  coefficientUB,
  coefficientLB
}: {
  tsp: number,
  tspUB: number,
  tspLB: number,
  coefficientUB: number,
  coefficientLB: number
}) => coefficientUB
  + (tsp - tspUB)
  * ((coefficientLB - coefficientUB) / (tspLB - tspUB));

export const calculateSystemCurvePoint = ({
  pointCFM,
  cfm,
  tsp
}: {
  pointCFM: number,
  cfm: number,
  tsp: number
}) => Math.pow(pointCFM, 2) / (Math.pow(cfm, 2) / tsp);

export const removeNTH = (number: number) => 
  number >= 0 
    ? Math.floor(number / 10)
    : Math.ceil(number / 10);
  
export const findMaxCFM = ({
  tsp,
  coefficient,
}: {
  tsp: number,
  coefficient: Coefficient,
}): number => {
  const roots = getCubicRoots(
    coefficient.a,
    coefficient.b,
    coefficient.c,
    coefficient.d - tsp
  ).map((r: any) => r.real) // this can be a complex number, so we only want the real parts
  let positiveRoots = roots.filter((root: any) => root >= 0 && root !== Infinity).sort((a: number, b: number) => a - b);
  const root = positiveRoots[0];
  // const root = positiveRoots[0];
  return root;
};

export const findUpperAndLowerTSP = ({
  cfm,
  tsp,
  fanCount,
  coefficients,
}: {
  cfm: number,
  tsp: number,
  fanCount: number,
  coefficients: RPMCoefficient[],
}) => {
  const sortedCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM);

  let tspUB = null;
  let rpmUB = null;

  let tspLB = null;
  let rpmLB = null;

  for (let coefficient of sortedCoefficients) {
    const newTsp = calculateTsp({
      cfm,
      fanCount,
      coefficient: coefficient.pressure
    });

    if (newTsp < tsp) {
      tspLB = newTsp;
      rpmLB = coefficient.RPM;
      break;
    } else {
      tspUB = newTsp;
      rpmUB = coefficient.RPM;
    }
  }

  if (tspUB === null) {
    tspUB = tspLB;
    rpmUB = rpmLB;
  }

  return {
    tspUB,
    rpmUB,
    tspLB,
    rpmLB,
  };
}

export const findDesignCoefficients = ({
  cfm,
  tsp,
  fanCount,
  coefficients,
  model
}: {
  cfm: number,
  tsp: number,
  fanCount: number,
  coefficients: RPMCoefficient[],
  model: string | undefined
}): DesignParameters => {
  const {
    tspUB,
    tspLB,
    rpmUB,
    rpmLB,
  } = findUpperAndLowerTSP({
    cfm,
    tsp,
    fanCount,
    coefficients
  });
  const safeTspUB = tspUB || 0;
  const safeTspLB = tspLB || 0;
  const coefficientsUB = coefficients.find((c) => c.RPM === rpmUB);
  const coefficientsLB = coefficients.find((c) => c.RPM === rpmLB);

  const a = calculateSingleCoefficient({
    tsp,
    tspLB: safeTspLB,
    tspUB: safeTspUB,
    coefficientLB: coefficientsLB?.pressure.a!,
    coefficientUB: coefficientsUB?.pressure.a!
  })

  const b = calculateSingleCoefficient({
    tsp,
    tspLB: safeTspLB,
    tspUB: safeTspUB,
    coefficientLB: coefficientsLB?.pressure.b!,
    coefficientUB: coefficientsUB?.pressure.b!
  })

  const c = calculateSingleCoefficient({
    tsp,
    tspLB: safeTspLB,
    tspUB: safeTspUB,
    coefficientLB: coefficientsLB?.pressure.c!,
    coefficientUB: coefficientsUB?.pressure.c!
  })

  const d = calculateSingleCoefficient({
    tsp,
    tspLB: safeTspLB,
    tspUB: safeTspUB,
    coefficientLB: coefficientsLB?.pressure.d!,
    coefficientUB: coefficientsUB?.pressure.d!
  })

  return {
    fanCount,
    upper: {
      tsp: safeTspUB,
      rpm: rpmUB,
      coefficient: coefficientsUB,
    },
    lower: {
      tsp: safeTspLB,
      rpm: rpmLB,
      coefficient: coefficientsLB
    },
    coefficient: {
      a,
      b,
      c,
      d
    } as Coefficient,
    interpolation: (safeTspUB - safeTspLB) !== 0 ? (tsp - safeTspLB) / (safeTspUB - safeTspLB) : 0,
  };
}

export const generatePoints: any = ({
  pressure,
  fanCount,
  cfmPoints,
}: {
  pressure: Coefficient,
  fanCount: number,
  cfmPoints: number[],
  modifier: number,
  tries: number,
}) => cfmPoints.map((cp) => ({
  x: cp,
  y: calculateTsp({
    cfm: cp,
    fanCount,
    coefficient: pressure
  })
}));

export const generateRPMCurve = ({
  fanCount,
  rpmCoefficient,
  dashed = false,
  points = 100,
  label = true,
  minimum = false,
}: {
  fanCount: number;
  rpmCoefficient: RPMCoefficient;
  dashed?: boolean;
  points?: number;
  label?: boolean;
  minimum?: boolean;
}) => {
  const { RPM, pressure } = rpmCoefficient;

  const roots = getCubicRoots(
    pressure.a,
    pressure.b,
    pressure.c,
    pressure.d
  ).map((r: any)=> r.real)
  let positiveRoots = roots.filter((root: any) => root >= 0 && root !== Infinity).sort((a: number, b: number) => a - b);
  const root = positiveRoots[0];
  const xIntercept = root * fanCount;
  const increment = xIntercept / (points - 1 );
  const cfmPoints = Array(points).fill(0).map((_, i) => increment * (i));
  
  const style = dashed
    ? {
        pointRadius: 0,
        borderWidth: 1.5,
        borderColor: '#1890cb',
        backgroundColor: '#1890cb',
        borderDash: [5, 8, 5],
      }
    : {
      borderWidth: 1.5,
      borderColor: '#1890cb',
      backgroundColor: '#1890cb',
    };
  
  const datalabels = label
    ? {
        display: !minimum,
        color: '#1890cb',
        align: -45,
        offset: 5,
        clamp: true,
        formatter: function(value: any, context: any) {
          if (context.dataIndex === 0) return `${RPM} RPM`;
          return '';
        }
      }
    : {
      display: false
    };

  return {
    ...style,
    datalabels,
    data: generatePoints({
      pressure,
      fanCount,
      cfmPoints,
      modifier: 1,
      tries: 0
    })
  };
}

export const generateSystemCurve = ({
  cfm,
  tsp,
  fanCount,
  points,
  maxRpmPressureCoefficients,
  color
}: {
  cfm: number,
  tsp: number,
  fanCount: number,
  points: number,
  maxRpmPressureCoefficients: Coefficient;
  color?: string
}) => {
  const n = tsp / Math.pow(cfm / fanCount, 2);
  const roots = getCubicRoots(
    maxRpmPressureCoefficients.a,
    maxRpmPressureCoefficients.b - n,
    maxRpmPressureCoefficients.c,
    maxRpmPressureCoefficients.d
  ).map((r: any)=> r.real)
  let positiveRoots = roots.filter((root: any) => root >= 0 && root !== Infinity).sort((a: number, b: number) => a - b);
  const root = positiveRoots[0];
  const systemLineMaxRPMXIntercept = root * fanCount;
  const increment = systemLineMaxRPMXIntercept / (points - 1 );
  const cfmPoints = Array(points).fill(0).map((_, i) => increment * (i));

  const systemCurveData = cfmPoints
    .map((p) => (
      {
        x: p,
        y: calculateSystemCurvePoint({ pointCFM: p, cfm, tsp })
      }
    ));

  return {
    pointRadius: 0,
    borderWidth: 1.5,
    borderColor: color || '#1890cb',
    backgroundColor: color || '#1890cb',
    borderDash: [5, 8, 5],
    datalabels: {
      display: false
    },
    data: systemCurveData,
    coefficients: {
      a: 0,
      b: n,
      c: 0,
      d: 0,
    }
  };
};

export const generateCutoffCurve = ({
  cutoffCoefficients,
  fanCount,
  maxRpmPressureCoefficients,
}: {
  maxRpmPressureCoefficients: Coefficient;
  fanCount: number;
  cutoffCoefficients: Coefficient;
}) => {
  const points = []

  for (let x = 0; x <= 10000; x += 100) {
    let cutoff = calculateTsp({ 
      cfm: x,
      fanCount: 1,
      coefficient: cutoffCoefficients
    })
    let pressure = calculateTsp({ 
      cfm: x,
      fanCount: 1,
      coefficient: maxRpmPressureCoefficients
    })
    if (cutoff >= pressure)
      cutoff = pressure;
    points.push({
      x: x * fanCount,
      y: cutoff
    })
    if (cutoff >= pressure)
      break;
  }

  return {
    pointRadius: 0,
    borderWidth: 1.5,
    borderColor: '#c9455e',
    backgroundColor: '#c9455e',
    datalabels: {
      display: false
    },
    data: points,
    coefficients: cutoffCoefficients
  };
};

export const findDesignPoint = (line1: Coefficient, line2: Coefficient, cfm: number, tsp: number, fanCount: number) => {
  if (!line1 || !line2) return { x:0, y:0 };

  const roots = getCubicRoots(
    line1.a - line2.a, 
    line1.b - line2.b, 
    line1.c - line2.c, 
    line1.d - line2.d
  ).map((r: any)=> r.real).filter((r: number) => r > 0)

  let positiveRoots = roots.filter((root: any) => root >= 0 && root !== Infinity).sort((a: number, b: number) => a - b);
  const root = positiveRoots[0];
  const x = root * fanCount;

  const y = Math.pow(x, 2) / (Math.pow(cfm, 2) / tsp)

  return { x, y };
}

const fanCurveMethods = {
  calculateTsp,
  calculateSingleCoefficient,
  calculateSystemCurvePoint,
  findMaxCFM,
  findUpperAndLowerTSP,
  findDesignCoefficients,
  generatePoints,
  generateRPMCurve,
  generateSystemCurve,
  findDesignPoint,
};

export default fanCurveMethods;