export interface HVACRecord {
  id?: number;
  created_on?: Date;
  updated_on?: Date;
}

export interface UserRole extends HVACRecord {
  name: string;
}

export interface User extends HVACRecord {
  firstName: string;
  lastName: string;
  email: string;
  roleId: number;
  authRef: string;
  // Aggregate data
  role?: UserRole; // Not sure if we'll do this so update once we know
}

export interface UserListItem {
  name: string;
  userId: number;
}

export enum SaleStatus {
  Open = 'open',
  Won = 'won',
  Close = 'close',
}