import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { GetQuotesByJobIdDocument, GetQuotesByJobIdQuery, useInsertQuoteMutation } from '../generated/graphql';
import { SaleStatus } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

// TODO: Do we want to show a loading indicator and close after success?
const AddQuoteModal = ({
  jobId,
  isOpen,
  warning = false,
  toggle,
  onSuccess
}: {
  jobId: number,
  isOpen: boolean,
  warning?: boolean,
  toggle: Function,
  onSuccess?: Function,
}) => {
  const [quote, setQuote] = useState({
    name: ''
  });
  const isValid = !!quote.name;

  const [createQuote, { loading }] = useInsertQuoteMutation();

  const onInput = (name: string, value: string) => {
    setQuote({
      ...quote,
      [name]: value
    });
  };

  const onCreate = () => {
    createQuote({
      variables: { object: { job_id: jobId, name: quote.name, status: SaleStatus.Open } },
      update: (cache, { data }) => {
        const existing = cache.readQuery<GetQuotesByJobIdQuery>({
          query: GetQuotesByJobIdDocument,
        });
        const inserted = data?.insert_quote_one;
        if (!inserted) return;
        cache.writeQuery({
          query: GetQuotesByJobIdDocument,
          data: {
            quote: existing?.quote.concat([{ ...inserted, products: [] }]),
          },
        });
      },
      onCompleted: (data) => {
        toggle();
        setQuote({
          name: ''
        });
        if (!!onSuccess) onSuccess(data.insert_quote_one?.id);
      }
    });
  };

  return (<>
    <Modal isOpen={isOpen} autoFocus={false} toggle={() => toggle()}>
      <div className="modal-header">
        <h3>Add new quote</h3>
        <FontAwesomeIcon
          style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
          onClick={() => { if (!loading) toggle(); }}
          icon={faXmarkCircle} />
      </div>
      <ModalBody>
        {warning && <p>You have not added a quote to this job yet. <b>Would you like to add a quote now?</b></p>}
        <div className="d-flex flex-column mb-3">
          <label>Name</label>
          <Input name="name" value={quote.name} autoFocus={true} onInput={(e) => onInput((e.target as any).name, (e.target as any).value)} />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-start mx-2">
        <Button disabled={loading || !isValid} onClick={onCreate}>
          {warning ? 'Yes, add a quote now' : 'Add Quote'}
        </Button>
        {warning && (<button className='btn btn-link' onClick={() => toggle()}>No, return to previous screen without adding a quote.</button>)}
      </ModalFooter>
    </Modal>
  </>);
};

export default AddQuoteModal;