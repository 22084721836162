import React from 'react';
import { Container } from 'reactstrap';
import UploadConfigurationsInput from '../components/UploadConfigurationsInput';

const UploadConfigurations = () => {

  return (
    <Container className="mt-3">
      <UploadConfigurationsInput />
    </Container>
  );
};

export default UploadConfigurations;