import React, { useCallback, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useGetJobByIdLazyQuery, useGetProductsLazyQuery, useGetQuotesByJobIdLazyQuery, useInsertJobMutation, useInsertQuotesMutation } from '../generated/graphql';

const DuplicateJobModal = ({
  jobId,
  onConfirm,
  onCancel,
}: {
  jobId: null | number,
  onConfirm: Function,
  onCancel: Function,
}) => {
  const [fetchJobById, { loading: loadingDetails, data}] = useGetJobByIdLazyQuery({
    variables: {
      id: jobId!
    }
  });
  const [fetchQuotesByJobId, { loading: loadingQuotes, data: quotesData }] = useGetQuotesByJobIdLazyQuery({
    variables: {
      jobId: jobId!
    }
  });
  const [fetchProducts, { loading: loadingProducts, data: productsData }] = useGetProductsLazyQuery({
    variables: {
      where: {
        job_id: { _eq: jobId },
      }
    }
  });

  const loading = loadingDetails || loadingQuotes || loadingProducts;

  const [createJob, { loading: creating }] = useInsertJobMutation();
  const [createQuotes, { loading: insertingQuotes }] = useInsertQuotesMutation();

  const handleDuplicate = useCallback(() => {
    const variables = {
      object: {
        name: data?.job_by_pk?.name,
        status: data?.job_by_pk?.status,
        quotes: { data: [] },
        products: { data: [] },
      }
    }

    let quotes: any = [];
    let products: any = [];

    if (!!quotesData?.quote) {
      quotes = quotesData.quote.map((q) => ({
        id: q.id,
        name: q.name,
        status: q.status,
        job_id: undefined,
      }));
    }

    if (!!productsData?.product) {
      products = productsData.product.map((p) => ({
        ...p,
        id: undefined,
        job_id: undefined,
        updated_at: undefined,
        created_at: undefined,
        __typename: undefined,
        configuration: undefined,
        has_serial_number: null,
      }));

      (variables.object.products as any).data = products.filter((p: any) => p.quote_id === null);
    }

    const quotesWithProducts: any = [];

    // Filter quotes with/without products and add products to their quotes for bulk insert
    quotes.forEach((q: any) => {
      const quoteProducts = products.filter((p: any) => p.quote_id === q.id);
      
      if (!quoteProducts.length) {
        (variables.object.quotes as any).data.push({
          ...q,
          id: undefined
        });
      } else {
        const quote = quotesWithProducts.find((qp: any) => q.id === qp.id);
        const cleanProducts = quoteProducts.map((p: any) => ({ ...p, quote_id: undefined }));

        if (!quote) {
          quotesWithProducts.push({
            ...q,
            id: undefined,
            products: cleanProducts
          });
        } else quote.products = cleanProducts;
      }
    });

    createJob({
      variables,
      update: async (cache, { data }) => {
        const inserted = data?.insert_job_one;
        if (!inserted) return;

        // Map job id onto both quotes and products that are linked together and bulk insert
        if (!!quotesWithProducts.length) {
          const cleanQuotesWithProducts = quotesWithProducts.map((q: any) => {
            const quote = {
              ...q,
              job_id: inserted.id,
              products: { data: q.products.map((p: any) => ({ ...p, job_id: inserted.id })) }
            };

            return quote;
          });

          await createQuotes({
            variables: {
              objects: cleanQuotesWithProducts
            },
            onCompleted: () => onConfirm()
          });
        }
      },
      onCompleted: () => !quotesWithProducts.length ? onConfirm() : () => {}
    });
  }, [data, createJob, productsData, quotesData, createQuotes, onConfirm]);
  
  useEffect(() => {
    if (!!jobId) {
      fetchJobById();
      fetchQuotesByJobId();
      fetchProducts();
    }
  }, [jobId, fetchJobById, fetchQuotesByJobId, fetchProducts]);

  return (
    <Modal isOpen={!!jobId} toggle={() => onCancel()}>
      <ModalBody>
        <h3>Are you sure?</h3>
        <p>Do you really want to duplicate the job {data?.job_by_pk?.name} along with its products and quotes?</p>
      </ModalBody>
      <ModalFooter className="mx-2">
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button
          style={{ backgroundColor: 'blue', borderColor: 'blue' }}
          disabled={creating || insertingQuotes || loading}
          onClick={() => handleDuplicate()}
        >Confirm</Button>
      </ModalFooter>
    </Modal>
  );
}

export default DuplicateJobModal;
