import React, { useState } from 'react';
import { Button, ModalBody, ModalFooter, Input } from 'reactstrap';
import { Quote, useUpdateQuoteMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

// TODO: Do we want to show a loading indicator and close after success?
const UpdateQuoteSettings = ({
  quote,
  setIsOpen,
  refetch
}: {
  quote: Quote,
  setIsOpen: Function,
  refetch: Function
}) => {
  const [name, setName] = useState<string>(quote.name);
  const [loading, setLoading] = useState(false);
  const [updateQuote] = useUpdateQuoteMutation();
  const isValid = name !== undefined && (name.length > 0 && name.length < 255);

  const onInput = (_: string, value: string) => {
    setName(value)
  };

  const save = () => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    updateQuote({
      variables: {
        pk_columns: { id: quote.id },
        _set: { name },
      },
      onCompleted: () => {
        refetch();
        setLoading(false);
        setIsOpen(false);
      },
      onError: () => {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <form onSubmit={(e) => {
        e.preventDefault();
        save();
      }}>
        <div className="modal-header">
          <h3>Update quote settings</h3>
          <FontAwesomeIcon
            style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
            onClick={() => { if (!loading) setIsOpen(false); }}
            icon={faXmarkCircle} />
        </div>
        <ModalBody>
          <div className="d-flex flex-column mb-3">
            <label>Name</label>
            <Input
              autoFocus
              name="name"
              min={1}
              max={999}
              value={name}
              onInput={(e) => onInput((e.target as any).name, (e.target as any).value)}
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start mx-2">
          <Button type='submit' disabled={loading || !isValid} onClick={save}>Update Quote</Button>
        </ModalFooter>
      </form>
    </div>
  );
};

export default UpdateQuoteSettings;