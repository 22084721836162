/* eslint-disable */
import Drawing, { Point2D } from 'dxf-writer'
import { writeFileSync } from 'fs'

const sharedMeasurements = {
    "height": 111.6,
    "returnFlangeDepth": 0.680,
    "channelDepth": 3.47 - 0.680,
    "channelNotch": 1,
    "outerBendLineDepth": 0.680,
    "innerBendLineDepth": 3.54 - 0.68,
    "roundedCornerRadius": 0.375,
    "stressReducerRadius": 0.149 / 2
}

class Cursor {
    points: Point2D[] = [];
    x = 0;
    y = 0;

    constructor(x = 0, y = 0) {
        this.points = [[x, y]];
        this.x = x;
        this.y = y;
    }

    public move(_x: number = 0, _y: number = 0) {
        this.x += _x;
        this.y += _y;
        this.points.push([this.x, this.y]);
    }
    public getPosition() { return [this.x, this.y]; }
    public clearPoints() {
        const points = this.points;
        this.points = [];
        return points;
    }
}

const stress_reducer_radius = 0.149 / 2
const channel_notch = 1 - stress_reducer_radius;
const rounded_corner_radius = 0.375

function drawPanel(
    drawing,
    { type, width, height, elements, core_width, return_flange_depth, channel_depth, outer_bend_line_depth, inner_bend_line_depth, channels }) {
    const has_left_channel = channels.indexOf("left") >= 0;
    const has_right_channel = channels.indexOf("right") >= 0;
    const channel_height = height - 2 * (1);
    const return_flange_adjusted_depth = return_flange_depth - rounded_corner_radius;
    const channel_adjusted_depth = return_flange_adjusted_depth + channel_depth - stress_reducer_radius;
    (function drawOuterEdges() {
        function recordLine() {
            drawing.drawPolyline(cursor.clearPoints(), false);
            cursor.clearPoints();
        }
        const cursor = has_left_channel ? new Cursor(-return_flange_adjusted_depth, channel_notch + stress_reducer_radius) : new Cursor(0, 0);
        if (has_left_channel) {
            cursor.move(channel_adjusted_depth)
            recordLine();
            cursor.move(stress_reducer_radius, -stress_reducer_radius);
            cursor.move(0, -channel_notch);
        }
        cursor.move(core_width)
        if (has_right_channel) {
            cursor.move(0, channel_notch);
            recordLine();
            cursor.move(stress_reducer_radius, stress_reducer_radius);
            cursor.move(channel_adjusted_depth);
            recordLine();
            cursor.move(rounded_corner_radius, rounded_corner_radius);
            cursor.move(0, channel_height - 2 * rounded_corner_radius);
            recordLine();
            cursor.move(-rounded_corner_radius, rounded_corner_radius);
            cursor.move(-channel_adjusted_depth);
            recordLine();
            cursor.move(-stress_reducer_radius, stress_reducer_radius);
            cursor.move(0, channel_notch);
        } else {
            cursor.move(0, height);
        }
        cursor.move(-core_width)
        if (has_left_channel) {
            cursor.move(0, -channel_notch);
            recordLine();
            cursor.move(-stress_reducer_radius, -stress_reducer_radius);
            cursor.move(-channel_adjusted_depth);
            recordLine();
            cursor.move(-rounded_corner_radius, -rounded_corner_radius);
            cursor.move(0, -(channel_height - 2 * rounded_corner_radius));
        } else {
            cursor.move(0, -height);
        }
        recordLine();
    })();

    (function drawHoles() {
        elements.filter(e => e.type === "hole").forEach(({ x, y, diameter, desc }) => {
            drawing.drawCircle(x, y, diameter / 2);
        })
    })();

    if (type === "fan") {
        (function drawFanHoles() {
            elements.filter(e => e.type === "fan").forEach(({ x, y, width, height }) => {
                drawing.drawRect(x - width / 2, y - height / 2, x + width / 2, y + height / 2);
                // drawing.drawCircle(x, y, diameter / 2);
            })
        })();
    }

    (function drawCornerArcs() {
        if (has_left_channel) {
            drawing.drawArc(-outer_bend_line_depth + rounded_corner_radius, channel_notch + stress_reducer_radius + rounded_corner_radius, rounded_corner_radius, 180, 270);
            drawing.drawArc(-outer_bend_line_depth + rounded_corner_radius, height - channel_notch - stress_reducer_radius - rounded_corner_radius, rounded_corner_radius, 90, 180);
        }
        if (has_right_channel) {
            drawing.drawArc(width + return_flange_adjusted_depth, channel_notch + stress_reducer_radius + rounded_corner_radius, rounded_corner_radius, 270, 0);
            drawing.drawArc(width + return_flange_adjusted_depth, height - channel_notch - stress_reducer_radius - rounded_corner_radius, rounded_corner_radius, 0, 90);
        }
    })();

    (function drawStressReducerArcs() {
        if (has_left_channel) {
            drawing.drawArc(channel_depth, channel_notch + stress_reducer_radius, stress_reducer_radius, 270, 360 + 180);
            drawing.drawArc(channel_depth, height - channel_notch - stress_reducer_radius, stress_reducer_radius, 180, 90);
        }
        if (has_right_channel) {
            drawing.drawArc(channel_depth * (channels.length - 1) + core_width, channel_notch + stress_reducer_radius, stress_reducer_radius, 360, 360 + 270);
            drawing.drawArc(channel_depth * (channels.length - 1) + core_width, height - channel_notch - stress_reducer_radius, stress_reducer_radius, 90, 0);
        }
    })();

    drawing.setActiveLayer("dashed-edges");
    (function drawBendLines() {
        if (has_left_channel) {
            drawing.drawLine(0, channel_notch + stress_reducer_radius, 0, height - (channel_notch + stress_reducer_radius));
            drawing.drawLine(channel_depth, channel_notch + (2 * stress_reducer_radius), channel_depth, height - channel_notch - (2 * stress_reducer_radius));
        }
        if (has_right_channel) {
            drawing.drawLine(width, channel_notch + stress_reducer_radius, width, height - (channel_notch + stress_reducer_radius));
            drawing.drawLine(core_width + channel_depth * (channels.length - 1), channel_notch + (2 * stress_reducer_radius), core_width + channel_depth * (channels.length - 1), height - channel_notch - (2 * stress_reducer_radius));
        }
    })();
}

// export enum PanelType {
//     Side,
//     Filler,
//     Fan
// }

export function getDrawing(panel: any) {
    let drawing: Drawing = new Drawing();

    drawing.setUnits('Inches');
    drawing.addLineType("MAS-DASHED", "__ _ __", [0.5, -0.1, 0.1, -0.1])
    drawing.addLayer("dashed-edges", 0, "MAS-DASHED");

    // if (type === PanelType.Side) {
    //     // drawSidePanel(drawing, panel, sharedMeasurements);
    // } else {
    //     // drawPanel(
    //     //     drawing,
    //     //     panel,
    //     //     sharedMeasurements
    //     // );
    // }

    drawPanel(drawing, panel)
    return drawing;
}