import "./Tools.css";
import { ChangeEvent, useState } from "react";
import { Input, Spinner } from "reactstrap";
import { Job, Product, Quote, useGetJobsForAdvancedSearchLazyQuery } from "../generated/graphql";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRightToFile } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../helpers/date-utils";

export default function Search() {

    const [searchParams, setSearchParams] = useState<any>({
        created_before: "", // job
        created_after: "", // job
        updated_before: "", // job
        updated_after: "", // job
        job_number: "", // quote
        quote_name: "", // quote
        serial_number: "", // product
        model_number: "", // product
        product_tag: "", // product
        fan_model: "", // product
        fan_quantity: "", // product
        controls_type: "any", // product
    });

    const [showType /*, setShowType*/] = useState({
        quote: true,
        job: true,
        product: true,
    })

    const [submittedParams, setSubmittedParams] = useState<any>({
        created_before: "", // job
        created_after: "", // job
        updated_before: "", // job
        updated_after: "", // job
        job_number: "", // quote
        quote_name: "", // quote
        serial_number: "", // product
        model_number: "", // product
        product_tag: "", // product
        fan_model: "", // product
        fan_quantity: "", // product
        controls_type: "any", // product
    });

    const [searchedParams, setSearchedParams] = useState<any>({
        job_number: "",
        quote_name: "",
        serial_number: "",
        model_number: "",
        product_tag: "",
        fan_model: "",
        fan_quantity: "",
        controls_type: "any",
    });

    const [queryAdvancedSearch, {loading, error, data}] = useGetJobsForAdvancedSearchLazyQuery();

    const onSubmit = (e : ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        queryAdvancedSearch({variables : searchParamsToVariables()});
        setSubmittedParams({...searchParams});
        setSearchedParams({
            job_number: (searchParams.job_number !== ""),
            quote_name: (searchParams.quote_name !== ""),
            serial_number: (searchParams.serial_number !== ""),
            model_number: (searchParams.model_number !== ""),
            product_tag: (searchParams.product_tag !== ""),
            fan_model: (searchParams.fan_model !== ""),
            fan_quantity: (searchParams.fan_quantity !== ""),
            controls_type: (searchParams.controls_type !== "any"),
        });
    }

    const searchParamsToVariables = () => {
        // Add filter for each non-null/empty field
        let where = {};
        if (!!searchParams.serial_number){
            Object.assign(where, {products: {has_serial_number: {serial_number: {_eq: searchParams.serial_number}}}});
        }
        if (!!searchParams.model_number){
            if(where.hasOwnProperty("products")) Object.assign(where["products"], {model_number: {_ilike: `%${searchParams.model_number}%`}})
            else Object.assign(where, {products: {model_number: {_like: `%${searchParams.model_number}%`}}});
        }
        if (!!searchParams.job_number){
            Object.assign(where, {quotes: {job_number: {_ilike: `%${searchParams.job_number}%`}}});
        }
        if (!!searchParams.quote_name){
            if(where.hasOwnProperty("quotes")) Object.assign(where["quotes"], {name: {_ilike: `%${searchParams.quote_name}%`}});
            Object.assign(where, {quotes: {name: {_ilike: `%${searchParams.quote_name}%`}}});
        }
        if (!!searchParams.product_tag){
            if(where.hasOwnProperty("products")) Object.assign(where["products"], {tag: {_like: `%${searchParams.product_tag}%`}})
            else Object.assign(where, {products: {tag: {_ilike: `%${searchParams.product_tag}%`}}});
        }
        if (!!searchParams.fan_model){
            if(where.hasOwnProperty("products")) Object.assign(where["products"], {configuration: {model: {_like: `%${searchParams.fan_model}%`}}});
            else Object.assign(where, {products: {configuration: {model: {_ilike: `%${searchParams.fan_model}%`}}}});
        }
        if (!!searchParams.fan_quantity){
            if(where.hasOwnProperty("products")) Object.assign(where["products"], {configured_fan_count: {_eq: searchParams.fan_quantity}});
            else Object.assign(where, {products: {configured_fan_count: {_eq: searchParams.fan_quantity}}});
        }
        if (searchParams.controls_type !== "any"){
            if (searchParams.controls_type === "basic"){
                if(where.hasOwnProperty("products")) Object.assign(where["products"], {mas_ec_plus: {_neq: true}});
                else Object.assign(where, {products: {mas_ec_plus: {_neq: true}}});
            } else if (searchParams.controls_type === "mas_ec_plus"){
                if(where.hasOwnProperty("products")) Object.assign(where["products"], {mas_ec_plus: {_eq: true}});
                else Object.assign(where, {products: {mas_ec_plus: {_eq: true}}});
            }
        }
        if (!!searchParams.created_after && !searchParams.created_before) {
            Object.assign(where, {created_on: {_gte: searchParams.created_after}});
        } else if (!searchParams.created_after && !!searchParams.created_before){
            Object.assign(where, {created_on: {_lte: searchParams.created_before}});
        } else if (!!searchParams.created_after && !!searchParams.created_before){
            Object.assign(where, {created_on: {_and: [{_lte: searchParams.created_before},{_gte: searchParams.created_after}]}});
        }
        if (!!searchParams.updated_after && !searchParams.updated_before) {
            Object.assign(where, {updated_on: {_gte: searchParams.updated_after}});
        } else if (!searchParams.updated_after && !!searchParams.updated_before){
            Object.assign(where, {updated_on: {_lte: searchParams.updated_before}});
        } else if (!!searchParams.updated_after && !!searchParams.updated_before){
            Object.assign(where, {updated_on: {_and: [{_lte: searchParams.updated_before},{_gte: searchParams.updated_after}]}});
        }
        return {
            order_by: {
              updated_on: 'desc'
            } as any,
            where
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";
    
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number") {
            try {
                value = parseFloat(e.target.value);
            } catch (error) {
                console.error("Parse error: ", error);
                return;
            }
        } else {
            value = e.target.value;
        }

        setSearchParams({ ...searchParams, [e.target.name]: value });
    }

    return(<div className="tools-content">
        <form onSubmit={onSubmit}>
            <div>
                <div className="box-label">Job Number</div>
                <Input
                    name="job_number"
                    type="text"
                    value={searchParams.job_number}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Serial Number</div>
                <Input
                    name="serial_number"
                    type="number"
                    value={searchParams.serial_number}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Quote Name</div>
                <Input
                    name="quote_name"
                    type="text"
                    value={searchParams.quote_name}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">MAS Model Number</div>
                <Input
                    name="model_number"
                    type="text"
                    value={searchParams.model_number}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Product Tag</div>
                <Input
                    name="product_tag"
                    type="text"
                    value={searchParams.product_tag}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Fan Model</div>
                <Input
                    name="fan_model"
                    type="text"
                    value={searchParams.fan_model}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Fan Quantity</div>
                <Input
                    name="fan_quantity"
                    type="number"
                    value={searchParams.fan_quantity}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Controls Type</div>
                <Input
                    name="controls_type"
                    type="select"
                    value={searchParams.controls_type}
                    onChange={handleChange}
                >
                    <option value="any">Any</option>
                    <option value="basic">Basic</option>
                    <option value="mas_ec_plus">MAS EC+</option>
                </Input>
            </div>
            <div>
                <div className="box-label">Created After</div>
                <Input
                    name="created_after"
                    type="date"
                    value={searchParams.created_after}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Created before</div>
                <Input
                    name="created_before"
                    type="date"
                    value={searchParams.created_before}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Updated After</div>
                <Input
                    name="updated_after"
                    type="date"
                    value={searchParams.updated_after}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Updated before</div>
                <Input
                    name="updated_before"
                    type="date"
                    value={searchParams.updated_before}
                    onChange={handleChange}
                />
            </div>
            <button type="submit">Submit</button>
        </form>
        <div className="results">
            {loading  &&
            <div className="center-message">
                Now Loading...
                <Spinner />
            </div>
            }
            {error && 
            <div className="center-message">
                <div style={{color:"#F00", fontSize:"20pt"}}>
                    ERROR
                </div>
                <div style={{color:"#888", fontSize:"1rem"}}>
                    Please let the programming team know what you were doing when this error occurred.
                </div>
            </div>
            }
            {data && 
                <>
                    <h3>Results:</h3>
                    {
                    <div className="search-holder">
                        {
                            (data.job || []).map((j : Job) => {
                                return(
                                    <>
                                        <div className="job-holder">
                                            <div className="job-entry">
                                                <JobEntry job={j} hidden={!showType.job}>
                                                {
                                                    (j.quotes || []).map((q : Quote) => {
                                                        let startQuoteCollapsed = true;
                                                        let boldedQuoteSections = {job_number:false,quote_name:false};
                                                        if (searchedParams.job_number && q.job_number.toLowerCase().includes(submittedParams.job_number.toLowerCase())){
                                                            startQuoteCollapsed = false;
                                                            boldedQuoteSections.job_number = true;
                                                        } 
                                                        if (searchedParams.quote_name && q.name.toLowerCase().includes(submittedParams.quote_name.toLowerCase())){
                                                            startQuoteCollapsed = false;
                                                            boldedQuoteSections.quote_name = true;
                                                        } 
                                                        // quote needs to be open if a product under it is open
                                                        for (const p of j.products){
                                                            if (p.quote_id === q.id){
                                                                if (searchedParams.serial_number){
                                                                    if (p.has_serial_number.length > 0){
                                                                        for (const serial_number of p.has_serial_number){
                                                                            if(serial_number.toString().includes(submittedParams.serial_number)) startQuoteCollapsed = false;
                                                                        }
                                                                    }
                                                                }
                                                                if (searchedParams.model_number && p.model_number.toLowerCase().includes(submittedParams.model_number.toLowerCase())) startQuoteCollapsed = false;
                                                                if (searchedParams.product_tag && p.tag.toLowerCase().includes(submittedParams.product_tag.toLowerCase())) startQuoteCollapsed = false;
                                                                if (searchedParams.fan_model && !!p?.configuration && p?.configuration.model.toLowerCase().includes(submittedParams.fan_model.toLowerCase())) startQuoteCollapsed = false;
                                                                if (searchedParams.fan_quantity && p.configured_fan_count === submittedParams.fan_quantity) startQuoteCollapsed = false;
                                                                if (searchedParams.controls_type){
                                                                    if (submittedParams.controls_type === "basic" && !p.mas_ec_plus) startQuoteCollapsed = false;
                                                                    if (submittedParams.controls_type === "mas_ec_plus" && p.mas_ec_plus) startQuoteCollapsed = false;
                                                                }
                                                            }
                                                        };
                                                        return (<>
                                                            <QuoteEntry quote={q} startingIndent={showType.job ? 2 : 0} startCollapsed={startQuoteCollapsed} hidden={!showType.quote} boldedSections={boldedQuoteSections}>
                                                            {
                                                                (j.products || []).map((p: Product) => {
                                                                    let startProductCollapsed = true;
                                                                    let boldedProductSections = {product_tag:false, model_number:false,fan_model:false,fan_quantity:false,serial_number:false,controls_type:false};
                                                                    if (searchedParams.serial_number){
                                                                        if (p.has_serial_number.length > 0){
                                                                            for (const serial_number of p.has_serial_number){
                                                                                if(serial_number.toString().includes(submittedParams.serial_number)){
                                                                                    startProductCollapsed = false;
                                                                                    boldedProductSections.serial_number = true;
                                                                                } 
                                                                            }
                                                                        }
                                                                    }
                                                                    if (searchedParams.model_number && p.model_number.toLowerCase().includes(submittedParams.model_number.toLowerCase())){
                                                                        startProductCollapsed = false;
                                                                        boldedProductSections.model_number = true;
                                                                    } 
                                                                    if (searchedParams.product_tag && p.tag.toLowerCase().includes(submittedParams.product_tag.toLowerCase())){
                                                                        startProductCollapsed = false;
                                                                        boldedProductSections.product_tag = true;
                                                                    } 
                                                                    if (searchedParams.fan_model && !!p?.configuration && p?.configuration.model.toLowerCase().includes(submittedParams.fan_model.toLowerCase())){
                                                                        startProductCollapsed = false;
                                                                        boldedProductSections.fan_model = true;
                                                                    } 
                                                                    if (searchedParams.fan_quantity && p.configured_fan_count === submittedParams.fan_quantity){
                                                                        startProductCollapsed = false;
                                                                        boldedProductSections.fan_quantity = true;
                                                                    } 
                                                                    if (searchedParams.controls_type){
                                                                        if (submittedParams.controls_type === "basic" && !p.mas_ec_plus){
                                                                            startProductCollapsed = false;
                                                                            boldedProductSections.controls_type = true;
                                                                        } 
                                                                        if (submittedParams.controls_type === "mas_ec_plus" && p.mas_ec_plus){
                                                                            startProductCollapsed = false;
                                                                            boldedProductSections.controls_type = true;
                                                                        } 
                                                                    }
                                                                    if (p.quote_id === q.id){
                                                                        return(<>
                                                                            <ProductEntry startingIndent={showType.job ? (showType.quote ? 4 : 2) : (showType.quote ? 2 : 0)} product={p} startCollapsed={startProductCollapsed} hidden={!showType.product} boldedSections={boldedProductSections}/>
                                                                        </>);
                                                                    } else {
                                                                        return(<></>);
                                                                    }
                                                                })
                                                            }
                                                            </QuoteEntry>
                                                        </>);
                                                    })
                                                }
                                                </JobEntry>
                                            </div>
                                            <div className="downloader-section">
                                                <Link to={"/jobs/" + j.id} className="">
                                                <FontAwesomeIcon icon={faArrowRightToFile}/>
                                                Go to Job
                                                </Link>
                                            </div>
                                        </div>
                                        
                                    </>
                                );
                            })
                        }
                        {
                            data.job.length === 0 && 
                            <>No results found</>
                        }
                    </div>
                }</>
            }
        </div>
    </div>);
};


function SearchRow ({indent, label, property, hidden=false, header=false, collapsed=false, toggleCollapse=()=>{}, bolded=false}) {
    return(
    <>
        <div className={ header ? "search-row-holder clickable" : "search-row-holder"} hidden={hidden} onClick={() => {toggleCollapse()}}>
            {indent > 0 && <div style={{width: `${22 * indent}px`, backgroundColor: "#ffffff00"}}></div>}
            <div className="search-row-label">
                {header &&
                    <FontAwesomeIcon className={collapsed ? "header-arrow" : "rotated-arrow"} icon={faAngleRight}/>
                }
                {label}
            </div>
            <div className={bolded ? "search-row-property found-property" : "search-row-property"}>{property}</div>
        </div>
        <div className="row-spacer" hidden={hidden}></div>
    </>
    )
}

function ProductEntry({product, hidden=false, startCollapsed=false, startingIndent, boldedSections={product_tag:false, model_number:false,fan_model:false,fan_quantity:false,serial_number:false,controls_type:false}}){

    const [collapsed, setCollapsed] = useState(startCollapsed);

    return(
        <>
            <SearchRow indent={startingIndent} label="Product Tag" property={product.tag} hidden={hidden} header={true} bolded={boldedSections.product_tag} collapsed={collapsed} toggleCollapse={()=>{setCollapsed(!collapsed)}}/>
            { !collapsed && 
                <>
                    <SearchRow indent={startingIndent+1} label="MAS Model Number" property={product.model_number ?? "Missing Information"} hidden={hidden} bolded={boldedSections.model_number}/>
                    <SearchRow indent={startingIndent+1} label="Fan Model" property={!!product?.configuration ? (product?.configuration.manufacturer + " " + product?.configuration.model) : "Not Set"} hidden={hidden} bolded={boldedSections.fan_model}/>
                    <SearchRow indent={startingIndent+1} label="Fan Quantity" property={product.configured_fan_count ?? "Not Set"} hidden={hidden} bolded={boldedSections.fan_quantity}/>
                    <SearchRow indent={startingIndent+1} label={product.has_serial_number.length <= 1 ? "Serial Number" : "Serial Numbers"} property={product.has_serial_number.length > 0 ? product.has_serial_number.map((e: { serial_number: any; }) => e.serial_number).join(", ") : "Not Assigned"} hidden={hidden} bolded={boldedSections.serial_number}/>
                    <SearchRow indent={startingIndent+1} label="Controls Type" property={product.mas_ec_plus ? "EC+ Controls" : "Basic Controls"} hidden={hidden} bolded={boldedSections.controls_type}/>
                </>
            }
        </>
    );
}

function QuoteEntry({quote, hidden=false, startCollapsed=false, startingIndent, boldedSections={quote_name:false, job_number:false}, children}){

    const [collapsed, setCollapsed] = useState(startCollapsed);

    return(
        <>
            <SearchRow indent={startingIndent} label="Quote Name" property={quote.name} hidden={hidden} header={true} bolded={boldedSections.quote_name} collapsed={collapsed} toggleCollapse={()=>{setCollapsed(!collapsed)}}/>
            { !collapsed &&
                <>
                    <SearchRow indent={startingIndent+1} label="Job Number" property={quote.job_number ?? "Not Set"} hidden={hidden} bolded={boldedSections.job_number}/>
                    {children}
                </>
            }
        </>
    )
}

function JobEntry({job, hidden=false, startCollapsed=false, children}){

    const [collapsed, setCollapsed] = useState(startCollapsed);

    return(
    <>
        <SearchRow indent={0} label="Job Name" property={job.name} hidden={hidden} header={true} collapsed={collapsed} toggleCollapse={()=>{setCollapsed(!collapsed)}}/>
        { !collapsed &&
            <>
                <SearchRow indent={1} label="Updated On" property={formatDate(job.updated_on)} hidden={hidden}/>
                <SearchRow indent={1} label="Created On" property={formatDate(job.created_on)} hidden={hidden}/>
                {children}
            </>
        }
    </>
    );
}